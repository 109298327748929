import * as React from 'react';
import classnames from 'classnames';

import { useRoutes } from '@src/hooks/useRoutes';
import { NavAcquisitionDesktop } from './NavAcquisitionDesktop';
import { NavAcquisitionMobile } from './NavAcquisitionMobile';

import styles from './navAcquisition.module.css';

export interface NavAcquisitionProps {}

/**
 * @name NavAcquisition
 * @description Very simple navigation bar we want across the application
 */
export const NavAcquisition = (_props: NavAcquisitionProps) => {
  // Hooks
  const { isAcquisitionRoute } = useRoutes();

  // Styles
  const cssComponent = classnames(styles.component, {
    [styles.acquisition]: isAcquisitionRoute
  });

  return (
    <nav className={cssComponent}>
      <div className="ui-desktop ">
        <NavAcquisitionDesktop />
      </div>
      <div className="ui-mobile">
        <NavAcquisitionMobile />
      </div>
    </nav>
  );
};

export async function sleep(msec = 5000): Promise<void> {
  return await new Promise(function (resolve) {
    setTimeout(resolve, msec);
  });
}
/**
 * @name getCurrentDateUTC
 * @description Get the time in UTC format for comparison
 */
export const getCurrentDateUTC = () => {
  const now = new Date();
  const date = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    )
  );

  return date;
};

/**
 * @name isHolidayPeriod
 * @description This utility function determines if a holiday discount
 * is available. As the user navigates the component will re-render providing
 * an update for any user that maybe started their navigation early, or is
 * navigating as the expiration date passes.
 * @external https://www.worldtimebuddy.com
 */
export const isHolidayPeriod = () => {
  const dateNow = getCurrentDateUTC();

  const dateStart = new Date(Date.UTC(2024, 3, 8, 4, 0)); // month is 0-based
  const dateEnd = new Date(Date.UTC(2024, 3, 16, 7, 0)); // Last day of the year

  // Timing stuff
  const timeNow = dateNow.getTime();
  const timeStart = dateStart.getTime();
  const timeEnd = dateEnd.getTime();
  const hasStarted = timeNow >= timeStart;
  const hasEnded = timeNow >= timeEnd;

  return hasStarted && !hasEnded;
};

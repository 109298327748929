import * as React from 'react';
import { useRecoilState } from 'recoil';

import { useRoutes } from '@src/hooks/useRoutes';
import { mobileAtom } from '@src/recoil/atoms/mobile';

export interface NavHamburgerProps {
  className?: string;
}

/**
 * @name NavHamburger
 * @description tbd...
 */
export const NavHamburger = (props: NavHamburgerProps) => {
  const { className } = props;

  // Hooks
  const [mobile, setMobile] = useRecoilState(mobileAtom);
  const { isNavForest } = useRoutes();

  // Setup
  const img = isNavForest ? 'menu-turmeric' : 'menu';
  const src = `/images/svg/${img}.svg`;

  // Handlers
  const onClickMobile = () => {
    setMobile({ ...mobile, open: true });
  };

  return (
    <div className={className} onClick={onClickMobile}>
      <img
        alt="Open navigation"
        className="flex items-center"
        loading="eager"
        src={src}
      />
    </div>
  );
};

import * as React from 'react';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { COOKIE_USER_ID } from '@src/config/constants';

export interface ElevarScriptProps {}

/**
 * @name ElevarScript
 * @description Script to load Elevar custom functions. This needs
 * to be loaded outside of next/head to avoid issues with DOM load order
 */
export const ElevarScript = (_props: ElevarScriptProps) => {
  // Hooks
  const { pathname } = useRouter();
  const [cookies] = useCookies([COOKIE_USER_ID]);

  // Setup
  const uuid = cookies[COOKIE_USER_ID];
  const listenerConfig = {
    data_layer_listener_script:
      'https://shopify-gtm-suite.getelevar.com/shops/ebc79a5ba01cd65be9be80395b0e14d8335473db/3.6.1/events.js',
    myshopify_domain: 'skinscripts.myshopify.com',
    signing_key: '6ZcdZnTcOjieKXxWhOUOUMxqhPrhgCJR',
    ss_url: 'https://hits.getelevar.com'
  };
  const aatConfig = {
    apex_domain: 'haldi.com',
    consent_enabled: false,
    data_layer_aat_script:
      'https://shopify-gtm-suite.getelevar.com/shops/ebc79a5ba01cd65be9be80395b0e14d8335473db/3.6.1/aat.js'
  };
  const conformityConfig = {
    data_layer_conformity_script:
      'https://shopify-gtm-suite.getelevar.com/shops/ebc79a5ba01cd65be9be80395b0e14d8335473db/3.6.1/conformity.js'
  };

  // Lifecycle
  React.useEffect(() => {
    window.ElevarUserIdFn = () => uuid;
  }, [pathname, uuid]);

  return (
    <>
      <Script
        strategy="beforeInteractive"
        id="elevar-dl-listener-config"
        type="application/json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(listenerConfig) }}
      />
      <Script
        strategy="beforeInteractive"
        id="elevar-dl-aat-config"
        type="application/json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(aatConfig) }}
      />
      <Script
        strategy="beforeInteractive"
        id="elevar-dl-conformity-config"
        type="application/json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(conformityConfig) }}
      />
      <Script
        id="elevar-dl-conformity"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `(() => {
          if (!window.__ElevarIsGtmSuiteConformityCalled) {
            window.__ElevarIsGtmSuiteConformityCalled = true;
            const configElement = document.getElementById(
              "elevar-dl-conformity-config"
            );
    
            if (!configElement) {
              console.error("Elevar: DLL Conformity element not found");
              return;
            }
    
            const config = JSON.parse(configElement.textContent);
    
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = config.data_layer_conformity_script;
            script.async = false;
            script.defer = true;
    
            script.onerror = function () {
              console.error("Elevar: DLL Conformity script failed to load");
            };

            document.head.appendChild(script);
          }
        })();`
        }}
      />
      <Script
        id="elevar-dl-listener"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `(() => {
          if (!window.__ElevarIsGtmSuiteListenerCalled) {
            window.__ElevarIsGtmSuiteListenerCalled = true;
            const configElement = document.getElementById(
              "elevar-dl-listener-config"
            );
    
            if (!configElement) {
              console.error("Elevar: DLL Config element not found");
              return;
            }
    
            const config = JSON.parse(configElement.textContent);
    
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = config.data_layer_listener_script;
            script.async = false;
            script.defer = true;
    
            script.onerror = function () {
              console.error("Elevar: DLL JS script failed to load");
            };
            script.onload = function () {
              if (!window.ElevarGtmSuiteListener) {
                console.error("Elevar: ElevarGtmSuiteListener is not defined");
                return;
              }
    
              window.ElevarGtmSuiteListener.handlers.listen({
                ssUrl: config.ss_url,
                signingKey: config.signing_key,
                myshopifyDomain: config.myshopify_domain
              });
            };
    
            document.head.appendChild(script);
          }
        })();`
        }}
      />
      <Script
        id="elevar-dl-aat"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `(() => {
          if (!window.__ElevarIsGtmSuiteAATCalled) {
            window.__ElevarIsGtmSuiteAATCalled = true;
            const init = () => {
              window.__ElevarDataLayerQueue = [];
              window.__ElevarListenerQueue = [];
              if (!window.dataLayer) window.dataLayer = [];
            };
            init();
            window.__ElevarTransformItem = event => {
              if (typeof window.ElevarTransformFn === "function") {
                try {
                  const result = window.ElevarTransformFn(event);
                  if (typeof result === "object" && !Array.isArray(result) && result !== null) {
                    return result;
                  } else {
                    console.error("Elevar Data Layer: window.ElevarTransformFn returned a value " + "that wasn't an object, so we've treated things as if this " + "function wasn't defined.");
                    return event;
                  }
                } catch (error) {
                  console.error("Elevar Data Layer: window.ElevarTransformFn threw an error, so " + "we've treated things as if this function wasn't defined. The " + "exact error is shown below.");
                  console.error(error);
                  return event;
                }
              } else {
                return event;
              }
            };
            window.ElevarPushToDataLayer = item => {
              const date = new Date();
              localStorage.setItem("___ELEVAR_GTM_SUITE--lastDlPushTimestamp", String(Math.floor(date.getTime() / 1000)));
              const enrichedItem = {
                event_id: window.crypto.randomUUID ? window.crypto.randomUUID() : String(Math.random()).replace("0.", ""),
                event_time: date.toISOString(),
                ...item
              };
              const transformedEnrichedItem = window.__ElevarTransformItem ? window.__ElevarTransformItem(enrichedItem) : enrichedItem;
              const payload = {
                raw: enrichedItem,
                transformed: transformedEnrichedItem
              };
              if (transformedEnrichedItem._elevar_internal?.isElevarContextPush) {
                window.__ElevarIsContextSet = true;
                window.__ElevarDataLayerQueue.unshift(payload);
                window.__ElevarListenerQueue.unshift(payload);
              } else {
                window.__ElevarDataLayerQueue.push(payload);
                window.__ElevarListenerQueue.push(payload);
              }
              window.dispatchEvent(new CustomEvent("elevar-listener-notify"));
              // if (window.__ElevarIsContextSet) {
                while (window.__ElevarDataLayerQueue.length > 0) {
                  const event = window.__ElevarDataLayerQueue.shift().transformed;
                  window.dataLayer.push(event);
                  console.info('Pushed to dataLayer:', event);
                }
              // }
            };
    
            const configElement = document.getElementById("elevar-dl-aat-config");
    
            if (!configElement) {
              console.error("Elevar: AAT Config element not found");
              return;
            }
    
            const config = JSON.parse(configElement.textContent);
    
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = config.data_layer_aat_script;
            script.async = false;
            script.defer = true;
    
            script.onerror = () => {
              console.error("Elevar: AAT JS script failed to load");
            };
            script.onload = async () => {
              if (!window.ElevarGtmSuiteAAT) {
                console.error("Elevar: ElevarGtmSuiteAAT is not defined");
                return;
              }
    
              window.ElevarGtmSuiteAAT.utils.emailCapture();
    
              await window.ElevarGtmSuiteAAT.handlers.register({
                apexDomain: config.apex_domain,
                isConsentEnabled: config.consent_enabled
              });
            };
    
            document.head.appendChild(script);
          }
        })();`
        }}
      />
    </>
  );
};

import { OverridedMixpanel } from 'mixpanel-browser';

import { IS_BROWSER, IS_PRODUCTION_ENV } from '@src/config/settings';
import { StoreIndexProductFieldsFragment } from '@src/generated/hooks';
import { User } from '@src/types';
import { logger } from '@src/utils/logger';
import { sleep } from './time';
/**
 * @description These events should be generic so that we can implement our
 * other more specific event requirements independently (facebook vs. google)
 *
 * @external https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
 * @external https://developers.facebook.com/docs/meta-pixel/reference
 * @external https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking/
 * @external https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching/
 */

const LOG_EVENTS = !IS_PRODUCTION_ENV && process.env.NODE_ENV !== 'test';
const MAX_IMPRESSIONS = 8;
export interface TrackingProduct {
  brand: string;
  category: string;
  id: string;
  indexProduct?: number;
  price: number;
  quantity: number;
  section?: string;
  title: string;
  variant_id: string;
  variant_name: string;
}

export interface ElevarProduct {
  brand: string;
  category: string;
  compare_at_price: string;
  id: string;
  image: string;
  inventory: string;
  list: string;
  name: string;
  position: number;
  price: string;
  product_id: string;
  quantity: string;
  variant: string;
  variant_id: string;
}

/**
 * @name checkAnalytics
 * @description Recurvisely check if analytics scripts are loaded yet
 */
export async function checkAnalytics(retries = 40): Promise<boolean> {
  const getUUID = IS_BROWSER ? window.ElevarUserIdFn : undefined;
  const datalayer = IS_BROWSER ? window.ElevarPushToDataLayer : undefined;

  if (getUUID && !!getUUID() && datalayer) {
    return true;
  } else if (retries > 0) {
    await sleep(250);
    return await checkAnalytics(retries - 1);
  }

  return false;
}

/**
 * @name trackingInitialize
 * @description Initialize tracking/analytics scripts.
 * This should be called on every page load and route change.
 */
export const trackingInitialize = () => {
  if (window.ElevarUserIdFn) {
    const uuid = window.ElevarUserIdFn();
    if (LOG_EVENTS) logger.info(`🎯 UUID:${uuid} 🎯`);
  }
  if (window.ElevarGtmSuiteAAT) {
    window.ElevarGtmSuiteAAT.utils.pushContextToDataLayer();
    if (LOG_EVENTS) logger.info(`🎯 pushContextToDataLayer 🎯`);
  }
  if (window.ElevarGtmSuiteListener) {
    window.ElevarGtmSuiteListener.utils.debug(true);
  }
};

/**
 * Format cart data for Elevar
 */
export const getProducts = (data: TrackingProduct[]) => {
  const products: ElevarProduct[] = data.map((product) => ({
    brand: product.brand,
    category: product.category ?? '',
    compare_at_price: '0.0',
    id: product.id,
    image: '',
    inventory: product.quantity?.toString() ?? '1',
    list: product.section ?? '',
    name: product.title,
    position: product.indexProduct ?? 0,
    price: product.price.toString(),
    product_id: product.id,
    quantity: '1',
    variant: product.variant_name,
    variant_id: product.variant_id
  }));

  return products;
};

/**
 * Format user data for Elevar
 */
export const getUserProperties = (user: User, code?: string) => {
  const isVisitor = !code && user.visitor;
  const name = user.name?.trim().split(' ') ?? [''];

  // Should be shopify data but we don't collect most of it
  const userProperties = {
    customer_address_1: '',
    customer_address_2: '',
    customer_city: '',
    customer_country: 'United States',
    customer_country_code: 'US',
    customer_email: user.email ?? '',
    customer_first_name: name[0],
    customer_id: code || '',
    customer_last_name: name[name.length - 1],
    customer_order_count: '',
    customer_phone: '',
    customer_province: '',
    customer_province_code: '',
    customer_tags: '',
    customer_total_spent: '',
    customer_zip: '',

    // Required
    user_consent: '',
    visitor_type: isVisitor ? 'guest' : 'logged_in'
  };

  return userProperties;
};
/**
 * @name trackingUserData
 * @description Initialize user_data for data layer.
 */
export const trackingUserData = (
  data: TrackingProduct[],
  user: User,
  code: string
) => {
  const event = 'dl_user_data';
  const userProperties = getUserProperties(user, code);
  const products = getProducts(data);
  const totalValue = data.reduce((acc, item) => {
    const q = item.quantity ?? 1;
    return acc + item.price * q;
  }, 0);

  if (window.ElevarPushToDataLayer) {
    window.ElevarPushToDataLayer({
      cart_total: totalValue.toString(),
      ecommerce: {
        cart_contents: { products },
        currencyCode: 'USD'
      },
      event,
      marketing: { user_id: window.ElevarUserIdFn() },
      user_properties: userProperties
    });

    if (LOG_EVENTS) {
      logger.info(`🎯 ${event} 🎯`);
    }
  }
};

/**
 * @name trackingLogin
 * @description Trigger both sign_up and sign_in events here.
 */
export const trackingLogin = (user: User, code: string, newSignUp: boolean) => {
  const event = newSignUp ? 'dl_sign_up' : 'dl_login';
  const userProperties = getUserProperties(user, code);

  if (window.ElevarPushToDataLayer) {
    window.ElevarPushToDataLayer({
      event,
      user_properties: userProperties
    });
    if (LOG_EVENTS) logger.info(`🎯 ${event} 🎯`);
  }
};

/**
 * @name trackingViewProducts
 * @description We trigger both an "item view" and "list view" events here.
 * Which event is determined by how many products are in the list, not taking
 * into account the "variants" of a product
 */
export const trackingViewProducts = (
  data: TrackingProduct[],
  isSingle = false,
  user: User,
  code: string
) => {
  const event = isSingle ? 'dl_view_item' : 'dl_view_item_list';
  const userProperties = getUserProperties(user, code);
  const products = getProducts(data);
  const impressions =
    products.length > MAX_IMPRESSIONS
      ? products.slice(0, MAX_IMPRESSIONS)
      : products;

  if (window.ElevarPushToDataLayer) {
    if (event === 'dl_view_item') {
      window.ElevarPushToDataLayer({
        ecommerce: {
          currencyCode: 'USD',
          detail: {
            actionField: {
              action: 'detail',
              list: products[0]?.list || 'store'
            },
            products
          }
        },
        event,
        user_properties: userProperties
      });
      if (LOG_EVENTS) logger.info({ products }, `🎯 ${event} 🎯`);
    } else if (event === 'dl_view_item_list') {
      window.ElevarPushToDataLayer({
        ecommerce: {
          currencyCode: 'USD',
          impressions
        },
        event,
        user_properties: userProperties
      });
      if (LOG_EVENTS) logger.info({ impressions }, `🎯 ${event} 🎯`);
    }
  }
};

/**
 * @name trackingAddToCart
 * @description Add to Cart | This event is triggered from the users store
 * and the bag route.
 */
export const trackingAddToCart = (
  data: TrackingProduct,
  user: User,
  code: string
) => {
  const event = 'dl_add_to_cart';
  const userProperties = getUserProperties(user, code);
  const products = getProducts([data]);

  if (window.ElevarPushToDataLayer) {
    window.ElevarPushToDataLayer({
      ecommerce: {
        add: {
          actionField: { list: products[0].list || 'Shopping Cart' },
          products
        },
        currencyCode: 'USD'
      },
      event,
      user_properties: userProperties
    });
    if (LOG_EVENTS) logger.info({ products }, `🎯 ${event} 🎯`);
  }
};

/**
 * @name trackingRemoveFromCart
 * @description Remove from Cart | This event is triggered from the users store
 * and the bag route.
 */
export const trackingRemoveFromCart = (
  data: TrackingProduct,
  user: User,
  code: string
) => {
  const event = 'dl_remove_from_cart';
  const userProperties = getUserProperties(user, code);
  const products = getProducts([data]);

  if (window.ElevarPushToDataLayer) {
    window.ElevarPushToDataLayer({
      ecommerce: {
        currencyCode: 'USD',
        remove: {
          actionField: { list: products[0].list || 'Shopping Cart' },
          products
        }
      },
      event,
      user_properties: userProperties
    });
    if (LOG_EVENTS) logger.info({ products }, `🎯 ${event} 🎯`);
  }
};

/**
 * @name trackingViewCart
 * @description This is triggered from the full bag/cart view
 */
export const trackingViewCart = (
  data: TrackingProduct[],
  user: User,
  code: string
) => {
  const event = 'dl_view_cart';
  const userProperties = getUserProperties(user, code);
  const products = getProducts(data);
  const impressions =
    products.length > MAX_IMPRESSIONS
      ? products.slice(0, MAX_IMPRESSIONS)
      : products;
  const totalValue = data.reduce((acc, item) => {
    const q = item.quantity ?? 1;
    return acc + item.price * q;
  }, 0);

  if (window.ElevarPushToDataLayer) {
    window.ElevarPushToDataLayer({
      cart_total: totalValue.toString(),
      ecommerce: {
        actionField: { list: 'Shopping Cart' },
        currencyCode: 'USD',
        impressions
      },
      event,
      user_properties: userProperties
    });
    if (LOG_EVENTS) logger.info({ impressions }, `🎯 ${event} 🎯`);
  }
};

/**
 * @name trackingCheckoutIntent
 * @description Checkout Intent | We fire this event when the user attempts
 * to checkout, when the data is valid they are redirected to Shopify for
 * payment details and redirected to a confirmation at the end of it all.
 */
export const trackingCheckoutIntent = (
  data: TrackingProduct[],
  user: User,
  code: string
) => {
  const event = 'dl_begin_checkout';
  const userProperties = getUserProperties(user, code);
  const products = getProducts(data);
  const totalValue = data.reduce((acc, item) => {
    const q = item.quantity ?? 1;
    return acc + item.price * q;
  }, 0);

  if (window.ElevarPushToDataLayer) {
    window.ElevarPushToDataLayer({
      cart_total: totalValue.toString(),
      ecommerce: {
        actionField: { list: products[0]?.list || 'Shopping Cart' },
        currencyCode: 'USD',
        products
      },
      event,
      marketing: { user_id: window.ElevarUserIdFn() },
      user_properties: userProperties
    });
    if (LOG_EVENTS) logger.info({ products }, `🎯 ${event} 🎯`);
  }
};

/**
 * @name getTrackingProductInfo
 * @description Format raw product data into the correct format for tracking
 */
export const getTrackingProductInfo = (
  products: StoreIndexProductFieldsFragment[],
  isMultiProduct = false,
  section = ''
): TrackingProduct[] => {
  const data: TrackingProduct[] = [];

  products.forEach((product, indexProduct) => {
    product.variants.map((variant, index) => {
      if (isMultiProduct && index !== 0) return;

      data.push({
        brand: product.brand,
        category: product.category,
        id: product.id,
        indexProduct,
        price: variant.price,
        quantity: variant.status !== 'ok' ? 0 : 1,
        section,
        title: product.title,
        variant_id: variant.id,
        variant_name: variant.name
      });
    });
  });

  return data;
};

/**
 * Survey events below
 */

/**
 * @name trackingSurveyStart
 * @description The user navigates to the survey and hopefully completes it,
 * we fire this on the component mount
 */
export const trackingSurveyStart = (
  url: string,
  version: number,
  mixpanel: OverridedMixpanel
) => {
  const event = 'survey_start';
  const user = { email: '', name: '', visitor: true };
  const userProperties = getUserProperties(user);

  mixpanel.track(event, { url, version });

  if (window.ElevarPushToDataLayer) {
    window.ElevarPushToDataLayer({
      event,
      marketing: { user_id: window.ElevarUserIdFn() },
      user_properties: userProperties
    });
    logger.info(`🎯 ${event} 🎯`);
  }
};

/**
 * @name trackingSurveyCompleted
 * @description Is fired only when a survey has been successfully submitted.
 * Ensuring this event fires 1x is important!
 */
export const trackingSurveyCompleted = (
  url: string,
  version: number,
  mixpanel: OverridedMixpanel
) => {
  const event = 'survey_completed';
  const user = { email: '', name: '', visitor: true };
  const userProperties = getUserProperties(user);

  mixpanel.track(event, { url, version });

  if (window.ElevarPushToDataLayer) {
    window.ElevarPushToDataLayer({
      event,
      marketing: { user_id: window.ElevarUserIdFn() },
      user_properties: userProperties
    });
    logger.info(`🎯 ${event} 🎯`);
  }
};

import { atom } from 'recoil';

import { Mobile } from '@src/types';

export const mobileDefault: Mobile = {
  open: false
};

/**
 * @name mobileAtom
 * @description This atom is used to track some state that is specific
 * to our users on mobile devices
 */
export const mobileAtom = atom<Mobile>({
  default: mobileDefault,
  key: 'mobile'
});

import { SITE_DESCRIPTION } from '@src/config/constants';
import { defaults } from '@src/services/seo/config/defaults';
import { getAppleMeta } from '@src/services/seo/metadata/apple';
import { getBrowserMeta } from '@src/services/seo/metadata/browsers';
import { getFacebookMeta } from '@src/services/seo/metadata/facebook';
import { getGeneralMeta } from '@src/services/seo/metadata/general';
import { getGoogleMeta } from '@src/services/seo/metadata/google';
import { getOpenGraphMeta } from '@src/services/seo/metadata/openGraph';
import { getTwitterMeta } from '@src/services/seo/metadata/twitter';

/**
 * @name getMetadata
 * @description This method is used to generate our metadata tags which
 * unlock a variety of SEO and Social "shareability" of our site/content
 */
const getMetadata = (
  pathname: string
): React.MetaHTMLAttributes<HTMLMetaElement>[] => {
  const metadata = defaults;

  // Override any defaults before generating the metadata
  switch (pathname) {
    case '/about-us':
      metadata.description =
        'The background of why Haldi was founded and the core principles that Haldi stands by.';
      break;

    case '/faq':
      metadata.description =
        "Answers to frequently asked questions about Haldi's custom skincare service.";
      break;

    case '/forgot-password':
      metadata.description =
        'Forgot your password? Reset your password here so you can log in to your Haldi account.';
      break;

    case '/legal/privacy':
      metadata.description =
        "Haldi's privacy policy and information on how we collect device information.";
      break;

    case '/legal/terms':
      metadata.description =
        "Haldi's terms of service, online store terms, disclaimers, and agreements.";
      break;

    case '/login':
      metadata.description =
        'Log in to your Haldi account to see your custom skincare store and routine.';
      break;

    case '/refer-a-friend':
      metadata.description =
        'Tell your friends about Haldi and earn free skincare credit through our referral program.';
      break;
    default:
      break;
  }

  // Dynamic paths
  const isSurveyPath = pathname?.startsWith('/surveys') ?? false;
  const isBrandPath = pathname?.startsWith('/brands') ?? false;

  if (isSurveyPath) {
    metadata.description =
      "Take Haldi's free skin quiz to get your own custom skincare routine.";
  } else if (isBrandPath) {
    metadata.description =
      'Learn more about the trusted brands that Haldi partners with.';
  } else if (pathname === '/') {
    metadata.description = SITE_DESCRIPTION;
  }

  return [
    ...getAppleMeta(metadata),
    ...getBrowserMeta(),
    ...getGeneralMeta(metadata),
    ...getFacebookMeta(),
    ...getGoogleMeta(metadata),
    ...getOpenGraphMeta(metadata),
    ...getTwitterMeta(metadata)
  ];
};

const metadata = getMetadata('');

export { getMetadata, metadata };

import * as React from 'react';

import { NavShopDesktop } from './NavShopDesktop';
import { NavShopMobile } from './NavShopMobile';

import styles from './navShop.module.css';

export interface NavShopProps {}

/**
 * @name NavShop
 * @description Very simple navigation bar we want across the application
 */
export const NavShop = (_props: NavShopProps) => {
  return (
    <div className={styles.component}>
      <NavShopDesktop className="ui-desktop" />
      <NavShopMobile className="ui-mobile" />
    </div>
  );
};

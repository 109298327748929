import * as React from 'react';
import classnames from 'classnames';

import { useRoutes } from '@src/hooks/useRoutes';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';

export interface DropdownCartEmptyProps {
  className?: string;
}

/**
 * @name DropdownCartEmpty
 * @description When the users cart is empty we prompt them with a bit of
 * UI to encourage them to visit the store and get shopping
 */
export const DropdownCartEmpty = (props: DropdownCartEmptyProps) => {
  const { className } = props;

  // Hooks
  const { isAnonymousRoute } = useRoutes();
  const href = isAnonymousRoute ? '/shop-mama' : '/store';

  // Styles
  const tailwind = `text-center`;
  const cssComponent = classnames(tailwind, className);

  return (
    <div className={cssComponent}>
      <img className="mt-1x" alt="empty-bag" src="/images/svg/empty-bag.svg" />
      <h3 className="mb-2x mt-3x text-16">Your bag is empty</h3>
      <HaldiButton className="mt-2x min-w-[160px]" href={href}>
        Shop now
      </HaldiButton>
    </div>
  );
};

import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';

import { initializeApollo } from '@src/config/apollo';
import {
  APOLLO_STATE,
  COOKIE_AUTH_REFRESH,
  COOKIE_AUTH_TOKEN
} from '@src/config/constants';
import { ApolloClientInstance, GenericJSONObject } from '@src/types';

/**
 * @name useApollo
 * @description Initializes our Apollo connection for use on the client-side,
 * updating as the inputs change.
 */
export function useApollo(
  pageProps: GenericJSONObject,
  jwt?: string
): ApolloClientInstance {
  // Hooks
  const { query } = useRouter();
  const [cookies] = useCookies([COOKIE_AUTH_REFRESH, COOKIE_AUTH_TOKEN]);

  // Setup
  const { code } = query;
  const paramCode = typeof code === 'string' ? code : undefined;
  const cookie = cookies[COOKIE_AUTH_TOKEN];
  const refresh = cookies[COOKIE_AUTH_REFRESH];
  const state = pageProps[APOLLO_STATE];
  const token = cookie || jwt;

  // if (cookie) console.log(`🍪 🍪 useApollo - cookie 🍪 🍪 `, !!cookie);
  // if (jwt) console.log(`🔐 🔐 useApollo - jwt 🔐 🔐 `, !!jwt);

  const store = useMemo(
    () =>
      initializeApollo({
        code: paramCode,
        initialState: state,
        refresh,
        token
      }),
    [state, code, token]
  );

  return store;
}

import * as React from 'react';

import { NavStoreDesktop } from './NavStoreDesktop';
import { NavStoreMobile } from './NavStoreMobile';

import styles from './navStore.module.css';

export interface NavStoreProps {}

/**
 * @name NavStore
 * @description Very simple navigation bar we want across the application
 */
export const NavStore = (_props: NavStoreProps) => {
  return (
    <div className={styles.component}>
      <NavStoreDesktop className="ui-desktop" />
      <NavStoreMobile className="ui-mobile" />
    </div>
  );
};

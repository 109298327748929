import { atom } from 'recoil';

import { User } from '@src/types';

export const userDefault: User = {
  email: '',
  name: '',
  username: '',
  visitor: true
};

/**
 * @name userAtom
 * @description This atom stores our current user
 */
export const userAtom = atom<User>({
  default: userDefault,
  key: 'user'
});

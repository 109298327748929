import {
  SITE_DESCRIPTION,
  SITE_SLOGAN,
  SITE_TITLE
} from '@src/config/constants';
import { CLIENT_BASE_URL } from '@src/config/settings';

export interface MetadataConfig {
  author: string;
  description: string;
  image: string;
  robots: string;
  site_name: string;
  title: string;
  type: string;
  url: string;
}

/**
 * @external https://ahrefs.com/blog/seo-meta-tags/
 * @description This is the base SEO structure
 */
const defaults: MetadataConfig = {
  author: `${SITE_TITLE}, Inc.`,
  description: SITE_DESCRIPTION,
  image: `${CLIENT_BASE_URL}/images/jpg/custom-routine-share.jpg`,
  robots: 'index, follow',
  site_name: SITE_TITLE,
  title: `${SITE_TITLE} | ${SITE_SLOGAN}`,
  type: 'website',
  url: CLIENT_BASE_URL
};

export { defaults };

import * as React from 'react';
import { color } from '@haldi/style-guide';
import classnames from 'classnames';

import { HaldiLogo } from '@src/routes/common/components/HaldiLogo';
import { AppLink } from '@src/services/app/components/AppLink';
import { SpotColor } from '@src/types';

import styles from './styles.module.css';

export interface NavLogoProps {
  background?: SpotColor;
  className?: string;
  colors?: string[];
}

/**
 * @name NavLogo
 * @description Wrapper around the logo that links to the homepage
 */
export const NavLogo = (props: NavLogoProps) => {
  const { background, className, colors } = props;

  // Setup
  let logoColors = colors;
  const { forest, pine, rust, stormy, turmeric, white } = color;

  /**
   * Basically we have some pre-defined colors for the logo when it's used
   * on a specific color background.
   */
  switch (background) {
    case 'forest':
      logoColors = [stormy, pine, turmeric, stormy, rust, pine, turmeric, stormy, turmeric, rust]; // prettier-ignore
      break;
    case 'pine':
      logoColors = [forest, white, turmeric, forest, turmeric, white, turmeric, forest, turmeric, white]; // prettier-ignore
      break;
    case 'rust':
      logoColors = [forest, white, turmeric, white, forest, forest, turmeric, white, forest, turmeric]; // prettier-ignore
      break;
    case 'turmeric':
      logoColors = [forest, white, pine, white, forest, forest, pine, white, forest, pine]; // prettier-ignore
      break;
    case 'white':
      logoColors = [forest, pine, turmeric, forest, rust, pine, forest, rust, turmeric, forest]; // prettier-ignore
      break;
    default:
      break;
  }

  // Styles
  const cssComponent = classnames('flex', styles.component, className);

  return (
    <AppLink className={cssComponent} href="/">
      <HaldiLogo colors={logoColors} />
    </AppLink>
  );
};

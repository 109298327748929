import * as React from 'react';
import classnames from 'classnames';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { useRoutes } from '@src/hooks/useRoutes';
import { useStoreFixedScroll } from '@src/hooks/useStoreFixedScroll';
import { useUrlParams } from '@src/hooks/useUrlParams';
import { appAtom } from '@src/recoil/atoms/app';
import { userAtom } from '@src/recoil/atoms/user';
import { NavCart } from '@src/services/navigation/components/NavCart';
import { NavHamburger } from '@src/services/navigation/components/NavHamburger';
import { NavLogo } from '@src/services/navigation/components/NavLogo';
import { NavStoreTitle } from '@src/services/navigation/components/NavStoreTitle';
import {
  searchHaldiBrands,
  searchHaldiCategories,
  searchHaldiProducts
} from '@src/utils/algolia-search';
import { NavLogoMama } from '../NavLogoMama/NavLogoMama';
import { NavSearch } from '../NavSearch/NavSearch';

import styles from './navStoreMobile.module.css';

export interface NavStoreMobileProps {
  className?: string;
}

/**
 * @name NavStoreMobile
 * @description Very simple navigation bar we want across the application
 */
export const NavStoreMobile = (props: NavStoreMobileProps) => {
  const { className } = props;

  // Hooks
  const { isActive } = useRoutes();
  const { visitor } = useRecoilValue(userAtom);
  const { data, loading } = useGetCustomerInfoQuery();
  const { isSticky } = useStoreFixedScroll();
  const { code } = useUrlParams();
  const [isSearching, setIsSearching] = React.useState(false);
  const setBlur = useSetRecoilState(appAtom);

  // Setup
  const isStorePage = isActive.store;
  const showAltHeader = isSticky && isStorePage;
  const isVisitor = !code && visitor;
  const isPregnancy = data?.customerInfo?.pregnancy ?? false;
  const handleSearchClick = () => {
    setIsSearching(!isSearching);
  };
  const handleSearchBlur = () => {
    setIsSearching(false);
    setBlur((state) => ({ ...state, typing: false }));
  };

  // Styles
  const cssComponent = classnames(`ui-container-lg`, className);
  const cssBtn = 'flex items-center justify-center h-full w-[60px]';
  const cssLogo = classnames('p-2xs', styles.logo);

  // Markup
  const renderLogo = () => {
    if (loading) return null;

    return isPregnancy ? (
      <NavLogoMama className="mt-1xs max-w-[200px] p-2xs" />
    ) : (
      <NavLogo className={cssLogo} />
    );
  };

  return (
    <div className={cssComponent}>
      <div className="z-[100] flex h-full items-center align-middle">
        <NavHamburger className={cssBtn} />
        {!isVisitor && isStorePage && (
          <img
            alt="Magnifying glass"
            className="w-4 hover:cursor-pointer"
            src="/icons/magnifier.svg"
            onClick={handleSearchClick}
          />
        )}
        <div className="flex-1" />
        {!showAltHeader && renderLogo()}
        {showAltHeader && <NavStoreTitle />}
        <div className="flex-1" />
        {isVisitor && <div className={cssBtn} />}
        {!isVisitor && <NavCart className={cssBtn} />}
      </div>
      {!isVisitor && isSearching && isStorePage && (
        <div>
          <div className="absolute top-[54px] left-0 w-full">
            <div
              className="flex h-14 items-center border-x-0 border-b-0 border-t-2 border-solid border-t-gray-1 bg-white"
              onBlur={handleSearchBlur}
            >
              <NavSearch
                suggestionPlugin={searchHaldiProducts(isPregnancy, code)}
                brandPlugin={searchHaldiBrands(isPregnancy, code)}
                categoryPlugin={searchHaldiCategories(isPregnancy, code)}
              />
            </div>
          </div>
          <div
            className="absolute right-6 mt-5 hover:cursor-pointer"
            onClick={handleSearchClick}
          >
            <img
              alt="Close"
              width="18"
              height="18"
              src="/images/svg/close.svg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

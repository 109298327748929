import { IMAGE_DEFAULT_PRODUCT } from '@src/config/constants';
import { FIREBASE_STORAGE_BUCKET } from '@src/config/settings';

/**
 * @name getImageThumbnail
 * @external https://console.firebase.google.com/u/1/project/haldi-staging/extensions/instances/storage-resize-images?tab=config
 * @description We store our users images in Firebase and as we upload these
 * we also use the extension to resize them to different sizes. This resizing
 * places the images in a "thumbs" folder with the size as a suffix to the image name
 */
export const getImageThumbnail = (src: string, size: 400 | 680 | 1200) => {
  const parts = src.split('/product_images/');

  if (parts.length === 1) return { jpg: src, webp: src };

  // Setup
  const [base, rest] = parts;
  const [filename, _ext] = rest.split('.');
  const jpg = `${base}/product_images/thumbs/${filename}_${size}x${size}.jpeg`;
  const webp = `${base}/product_images/thumbs/${filename}_${size}x${size}.webp`;

  return { jpg, webp };
};

/**
 * @name getProductImage
 * @description Convert an product_image filename into a full url
 * By default, use the thumbnail compressed version
 */
export const getProductImage = (filename?: string, size?: 400 | 680 | 1200) => {
  if (!filename || filename === 'default') {
    const original = IMAGE_DEFAULT_PRODUCT;
    return { jpg: original, original, webp: original };
  }

  const original = `https://${FIREBASE_STORAGE_BUCKET}/${filename}`;
  const { jpg, webp } = getImageThumbnail(original, size ?? 1200);

  return { jpg, original, webp };
};

import { MetadataConfig } from '@src/services/seo/config/defaults';

const getTwitterMeta = (
  metadata: MetadataConfig
): React.MetaHTMLAttributes<HTMLMetaElement>[] => [
  {
    content: `summary`,
    property: 'twitter:card' // summary | summary_large_image
  },
  {
    content: `@haldiskin`,
    property: 'twitter:creator'
  },
  {
    content: metadata.description,
    property: 'twitter:description'
  },
  {
    content: metadata.image,
    property: 'twitter:image'
  },
  {
    content: `@haldiskin`,
    property: 'twitter:site'
  },
  {
    content: metadata.title,
    property: 'twitter:title'
  }
];

export { getTwitterMeta };

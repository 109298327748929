import * as React from 'react';
import classnames from 'classnames';

import { SITE_HALDI_MAMA } from '@src/config/constants';
import { AppLink } from '@src/services/app/components/AppLink';
import { AppPicture } from '@src/services/app/components/AppPicture/AppPicture';

import styles from './styles.module.css';

export interface NavLogoMamaProps {
  className?: string;
}

/**
 * @name NavLogoMama
 * @description Wrapper around the logo that links to the homepage
 */
export const NavLogoMama = (props: NavLogoMamaProps) => {
  const { className } = props;

  // Styles
  const cssComponent = classnames('flex', styles.component, className);

  return (
    <AppLink className={cssComponent} href={SITE_HALDI_MAMA}>
      <AppPicture png="/images/png/haldimama_logo.png" />
    </AppLink>
  );
};

/**
 * @name isExternalLink
 * @description We treat all links as external if they start with http or https
 * and when opening we do so in a new tab.
 */
export function isExternalLink(href?: string): boolean {
  const isExternal = href?.startsWith('http') ?? false;

  return isExternal;
}

import { MetadataConfig } from '@src/services/seo/config/defaults';

const getOpenGraphMeta = (
  metadata: MetadataConfig
): React.MetaHTMLAttributes<HTMLMetaElement>[] => [
  {
    content: metadata.description,
    property: 'og:description'
  },
  {
    content: metadata.image,
    property: 'og:image'
  },
  {
    content: metadata.site_name,
    property: 'og:site_name'
  },
  {
    content: metadata.title,
    property: 'og:title'
  },
  {
    content: metadata.type,
    property: 'og:type'
  },
  {
    content: metadata.url,
    property: 'og:url'
  }
];

export { getOpenGraphMeta };

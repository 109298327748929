import * as React from 'react';
import classnames from 'classnames';

import { useGetProfileFriendsQuery } from '@src/generated/hooks';
import { HasReferrals } from './HasReferrals';
import { NoPurchased } from './NoPurchased';
import { NoReferrals } from './NoReferrals';

import styles from './styles.module.css';

export interface DropdownTrackerProps {
  className?: string;
}

/**
 * @name DropdownTracker
 * @description This component is used to information related to the current
 * users referrals and any available balance (credits).
 */
export const DropdownTracker = (props: DropdownTrackerProps) => {
  const { className } = props;

  // Hooks
  const { data, error, loading } = useGetProfileFriendsQuery();

  // Styles
  const tailwind = 'flex flex-col';
  const cssComponent = classnames(tailwind, styles.component, className);

  // 🔌 Short Circuit
  if (!data || error || loading) return null;

  // Setup
  const { discountCode, friendsReferral } = data;
  const { count } = friendsReferral;
  const { purchased, surveyed } = count;
  const noPurchased = purchased === 0 && surveyed !== 0;
  const noReferrals = purchased === 0 && surveyed === 0;
  const hasReferrals = !noPurchased && !noReferrals;

  // Markup
  const renderReferrals = () => <HasReferrals code={discountCode} />;

  return (
    <div className={cssComponent}>
      {hasReferrals && renderReferrals()}
      {noPurchased && <NoPurchased referrals={friendsReferral} />}
      {noReferrals && <NoReferrals />}
    </div>
  );
};

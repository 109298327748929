import { CookieSetOptions } from 'universal-cookie';

import { IS_DEVELOPMENT } from './settings';

export const APOLLO_STATE = 'APOLLO_STATE';

export const DATE_TODAY = new Date();
export const DATE_ONE_YEAR = 1000 * 60 * 60 * 24 * 365;
export const DATE_ONE_YEAR_EXP = DATE_TODAY.getTime() + DATE_ONE_YEAR;

export const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric'
};

export const COOKIE_AUTH_REFRESH = 'haldi_refresh';
export const COOKIE_AUTH_TOKEN = 'haldi_auth';
export const COOKIE_BANNER = 'haldi_banner';
export const COOKIE_BETA = 'haldi_beta';
export const COOKIE_DISCLAIMER = 'haldi_disclaimer';
export const COOKIE_USER_ID = 'haldi_uuid';
export const COOKIE_VISITOR = 'haldi_visitor';

export const COOKIE_OPTIONS: CookieSetOptions = {
  expires: new Date(DATE_ONE_YEAR_EXP),
  path: '/',
  sameSite: 'none',
  secure: true
};

export const DEBUG_MARKERS = IS_DEVELOPMENT
  ? { endColor: 'red', fontSize: '12px', startColor: 'green' }
  : undefined;

export const HEADER_VALID_CODES = ['PR', 'US'];
export const HEADER_IPCOUNTRY = 'x-vercel-ip-country';

export const HEADER_AUTH_KEY = 'authorization';
export const HEADER_AUTH_REFRESH = 'refresh';
export const HEADER_USER_AGENT = 'user-agent';
export const HEADER_USER_ID = 'code';

export const IMAGE_DEFAULT_PRODUCT = `/images/svg/default-haldi.svg`;
export const IMAGE_DEFAULT_USER_PRODUCT = `/images/svg/default-product.svg`;

export const PATH_AFTER_FROM_LOGIN = '/profile/summary';
export const PATH_LOGIN = '/login';
export const PATH_USER_PHOTOS = 'user_profile_photos';

export const PROFILE_EMAIL_ADDRESS = `teamhaldi@haldi.com`;
export const PROFILE_EMAIL_LINK = `mailto:${PROFILE_EMAIL_ADDRESS}`;

export const SITE_HEADER = `We sift through hundreds of products to build the best custom skincare routine for you.`;
export const SITE_DESCRIPTION = `We sift through hundreds of products and cult brands to curate the best custom skincare routine for you. Take our free skin quiz to get your new routine.`;
export const SITE_EMAIL_ADDRESS = `teamhaldi@haldi.com`;
export const SITE_EMAIL_LINK = `mailto:${SITE_EMAIL_ADDRESS}`;
export const SITE_FACEBOOK = 'https://www.facebook.com/haldiskin';
export const SITE_HALDI_MAMA = 'https://www.haldimama.com';
export const SITE_HALDI_MAMA_MISSION = 'https://mission.haldimama.com';
export const SITE_INSTAGRAM = 'https://www.instagram.com/haldiskin';
export const SITE_SLOGAN = `Custom skincare routine to fit your skin needs`;
export const SITE_SURVEY_PATH = '/surveys/custom-skincare-quiz';
export const SITE_SURVEY_PREGNANCY_PATH = `/surveys/custom-skincare-quiz-4`;
export const SITE_SURVEY_CTA = 'Take skin quiz';
export const SITE_TITLE = 'Haldi';
export const SITE_URL = 'https://haldi.com';

export const SEARCH_DEBOUNCE_DELAY = 300;
export const SURVEY_SUGGEST_TEXT = `👉 Suggest a new product... `;

export const TOAST_LOCATION = 'bottom-right';
export const TOAST_DURATION = 3000;

export const URL_PARAM_DRAFT = 'admin_draft';

export const CART_GIFT_MIN_AMOUNT = 75.0;
export const MAX_CHAR_LIMIT = 500;

import { MetadataConfig } from '@src/services/seo/config/defaults';

const getGeneralMeta = (
  metadata: MetadataConfig
): React.MetaHTMLAttributes<HTMLMetaElement>[] => [
  {
    content: metadata.author,
    name: 'author'
  },
  {
    content: metadata.description,
    name: 'description'
  },
  {
    content: metadata.image,
    name: 'image'
  },
  {
    content: metadata.title,
    name: 'name'
  }
];

export { getGeneralMeta };

import pino, { Logger, LoggerOptions } from 'pino';

import {
  COOKIE_AUTH_REFRESH,
  COOKIE_AUTH_TOKEN,
  HEADER_AUTH_KEY,
  HEADER_AUTH_REFRESH
} from '@src/config/constants';
import { IS_PRODUCTION } from '@src/config/settings';

const config: LoggerOptions = {
  name: 'haldi-client',
  redact: [
    `req.cookies.${COOKIE_AUTH_REFRESH}`,
    `req.cookies.${COOKIE_AUTH_TOKEN}`,
    `req.headers.${HEADER_AUTH_KEY}`,
    `req.headers.${HEADER_AUTH_REFRESH}`
  ],
  transport: {
    options: {
      colorize: true,
      ignore: 'hostname,pid',
      levelFirst: true,
      translateTime: 'SYS:standard'
    },
    target: 'pino-pretty'
  }
};

/**
 * @name logger
 * @description A high-performance logger for NodeJS applications
 */
const logger: Logger = pino(IS_PRODUCTION ? {} : config);

export { logger };
export type { Logger };

import * as React from 'react';
import { color } from '@haldi/style-guide';
import classnames from 'classnames';

import styles from './styles.module.css';

export interface HaldiLogoProps {
  className?: string;
  colors?: string[];
}

/**
 * @name HaldiLogo
 * @description Our logo as a React SVG component which makes it easy to style
 * using our @haldi/style-guide spot colors. The paths are ordered from top to
 * bottom mapping to visually, left to right. Each path can be colored as needed
 * giving us a ton of varaitons to choose from.
 */
export const HaldiLogo = (props: HaldiLogoProps) => {
  // Setup
  const { forest, pine, turmeric, rust } = color;
  const defaults = [ forest, pine, turmeric, forest, rust, pine, forest, rust, turmeric, forest ]; // prettier-ignore
  const { className, colors = defaults } = props;

  // Styles
  const cssComponent = classnames(styles.component, className);

  return (
    <svg
      className={cssComponent}
      fill="none"
      height="80"
      viewBox="0 0 341 80"
      width="341"
    >
      <path
        d="M13.95 0H1.26C0.564121 0 0 0.564121 0 1.26V77.69C0 78.3859 0.564121 78.95 1.26 78.95H13.95C14.6459 78.95 15.21 78.3859 15.21 77.69V1.26C15.21 0.564121 14.6459 0 13.95 0Z"
        fill={colors[0]}
      />
      <path
        d="M50.08 37.91H22.37C21.6741 37.91 21.11 38.4742 21.11 39.17V45.21C21.11 45.9059 21.6741 46.47 22.37 46.47H50.08C50.7759 46.47 51.34 45.9059 51.34 45.21V39.17C51.34 38.4742 50.7759 37.91 50.08 37.91Z"
        fill={colors[1]}
      />
      <path
        d="M65.42 0H58.31C57.6141 0 57.05 0.564121 57.05 1.26V77.69C57.05 78.3859 57.6141 78.95 58.31 78.95H65.42C66.1159 78.95 66.68 78.3859 66.68 77.69V1.26C66.68 0.564121 66.1159 0 65.42 0Z"
        fill={colors[2]}
      />
      <path
        d="M129.97 0.879974C129.88 0.639327 129.719 0.431832 129.508 0.285278C129.297 0.138725 129.047 0.0601357 128.79 0.0599976H109.54C109.285 0.0607565 109.035 0.13974 108.826 0.286346C108.617 0.432953 108.458 0.640121 108.37 0.879974L80.36 77.3C80.291 77.49 80.2688 77.6938 80.2951 77.8941C80.3214 78.0945 80.3956 78.2856 80.5113 78.4513C80.627 78.617 80.7808 78.7525 80.9599 78.8462C81.1389 78.94 81.3379 78.9893 81.54 78.99H95.09C95.3468 78.9899 95.5975 78.9112 95.8084 78.7647C96.0193 78.6181 96.1803 78.4106 96.27 78.17L121.27 8.31998C121.27 8.31998 121.49 7.31998 122.38 7.31998H122.72C122.997 7.31838 123.265 7.41978 123.472 7.60452C123.679 7.78926 123.81 8.04419 123.84 8.31998C123.97 9.99803 124.306 11.6538 124.84 13.25L149.14 78.17C149.23 78.4106 149.391 78.6181 149.602 78.7647C149.813 78.9112 150.063 78.9899 150.32 78.99H156.85C157.052 78.9893 157.251 78.94 157.43 78.8462C157.609 78.7525 157.763 78.617 157.879 78.4513C157.994 78.2856 158.069 78.0945 158.095 77.8941C158.121 77.6938 158.099 77.49 158.03 77.3L129.97 0.879974Z"
        fill={colors[3]}
      />
      <path
        d="M134.04 50.99H110.55C109.854 50.99 109.29 51.5541 109.29 52.25V58.29C109.29 58.9859 109.854 59.55 110.55 59.55H134.04C134.736 59.55 135.3 58.9859 135.3 58.29V52.25C135.3 51.5541 134.736 50.99 134.04 50.99Z"
        fill={colors[4]}
      />
      <path
        d="M184.08 0H171.39C170.694 0 170.13 0.564121 170.13 1.26V77.69C170.13 78.3859 170.694 78.95 171.39 78.95H184.08C184.776 78.95 185.34 78.3859 185.34 77.69V1.26C185.34 0.564121 184.776 0 184.08 0Z"
        fill={colors[5]}
      />
      <path
        d="M224.33 70.4H192.4C191.704 70.4 191.14 70.9641 191.14 71.66V77.7C191.14 78.3959 191.704 78.96 192.4 78.96H224.33C225.026 78.96 225.59 78.3959 225.59 77.7V71.66C225.59 70.9641 225.026 70.4 224.33 70.4Z"
        fill={colors[6]}
      />
      <path
        d="M253.59 0.0999756H240.9C240.204 0.0999756 239.64 0.664097 239.64 1.35998V77.79C239.64 78.4859 240.204 79.05 240.9 79.05H253.59C254.286 79.05 254.85 78.4859 254.85 77.79V1.35998C254.85 0.664097 254.286 0.0999756 253.59 0.0999756Z"
        fill={colors[7]}
      />
      <path
        d="M299.73 10.55C292.62 3.84002 282.73 0.150024 271.98 0.150024H262.91C262.397 0.162701 261.908 0.372215 261.545 0.735199C261.182 1.09818 260.973 1.58683 260.96 2.10001V5.79001C260.973 6.30319 261.182 6.79186 261.545 7.15485C261.908 7.51783 262.397 7.72734 262.91 7.74002H267.76C275.4 7.74002 282.27 9.38 287.09 15.41C292.21 21.81 294.92 27.65 294.92 38.68C294.92 49.71 292.21 55.55 287.09 61.95C282.27 67.95 275.4 70.46 267.76 70.46H262.91C262.397 70.4727 261.908 70.6822 261.545 71.0452C261.182 71.4082 260.973 71.8968 260.96 72.41V77.06C260.973 77.5732 261.182 78.0619 261.545 78.4248C261.908 78.7878 262.397 78.9973 262.91 79.01H271.98C282.77 79.01 292.62 75.32 299.73 68.61C307.37 61.4 311.41 51.36 311.41 39.61C311.41 27.86 307.36 17.75 299.73 10.55Z"
        fill={colors[8]}
      />
      <path
        d="M339.1 0H326.41C325.714 0 325.15 0.564121 325.15 1.26V77.9C325.15 78.5959 325.714 79.16 326.41 79.16H339.1C339.796 79.16 340.36 78.5959 340.36 77.9V1.26C340.36 0.564121 339.796 0 339.1 0Z"
        fill={colors[9]}
      />
    </svg>
  );
};

import * as React from 'react';

import { useRoutes } from '@src/hooks/useRoutes';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';

export interface DropdownCartButtonProps {
  className?: string;
  count: number;
}

/**
 * @name DropdownCartButton
 * @description This button is shown when the user has items in their cart
 * and redirects them to the store-front to shop
 */
export const DropdownCartButton = (props: DropdownCartButtonProps) => {
  const { count } = props;

  const { isAnonymousRoute } = useRoutes();
  const href = isAnonymousRoute ? '/shop-mama/bag' : '/store/bag';

  return (
    <HaldiButton className="w-full text-center" href={href}>
      View my bag ({count})
    </HaldiButton>
  );
};

import * as React from 'react';
import classnames from 'classnames';
import keyBy from 'lodash/keyBy';

import { DropdownCartButton } from '@src/components/DropdownCartButton';
import { DropdownCartEmpty } from '@src/components/DropdownCartEmpty';
import {
  CartProduct,
  useGetCustomerCartQuery,
  useGetStoreIndexQuery
} from '@src/generated/hooks';
import { useRoutes } from '@src/hooks/useRoutes';
import { useUrlParams } from '@src/hooks/useUrlParams';
import { getProductTotals } from '@src/services/navigation/utils';
import { DropdownCartItem } from '../DropdownCartItem/DropdownCartItem';

import styles from './styles.module.css';

export interface DropdownCartProps {
  className?: string;
  uuid?: string;
}

/**
 * @name DropdownCart
 * @description Shows the users current cart count and reveals the truncated
 * list of their current cart on hover. This component handles grabbing the
 * current cart data and passes the necessary bits down to some stateless
 * UI components
 */
export const DropdownCart = (props: DropdownCartProps) => {
  const { className, uuid } = props;

  // Hooks
  const { code } = useUrlParams();
  const { isAnonymousRoute } = useRoutes();
  const { data } = useGetCustomerCartQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { input: { uuid } }
  });
  const { data: storeProducts } = useGetStoreIndexQuery({
    skip: isAnonymousRoute,
    variables: { inputProducts: {} }
  });

  // Setup
  const productsMap = keyBy(storeProducts?.personaStoreProducts.products ?? [], 'id'); // prettier-ignore
  const products = data?.customerCart.products ?? [];
  const quantity = products.reduce(getProductTotals, 0);

  // Styles
  const cssComponent = classnames(styles.component, className, {
    [styles.empty]: quantity === 0
  });

  // Markup
  const renderCartItem = (product: CartProduct) => {
    const { productId, variantId } = product;
    const key = `${productId}-${variantId}`;
    const productData = productsMap[productId];

    return (
      <DropdownCartItem
        data={product}
        key={key}
        productData={productData}
        code={code}
      />
    );
  };

  // 🔌 Short Circuit
  if (!products?.length) {
    return <DropdownCartEmpty className={cssComponent} />;
  }

  return (
    <div {...props} className={cssComponent}>
      <div className="mb-2x text-center text-12 uppercase">Added to bag</div>
      {products.map(renderCartItem)}
      <DropdownCartButton count={quantity} />
    </div>
  );
};

import { useRouter } from 'next/router';

const ADMIN_DRAFT = 'admin_draft';

type UseUrlParamsResult = {
  code: string;
  viewDraft: boolean;
  personaId?: string;
};

/**
 * @name getCode
 * @description Returns value of the 'code' url parameter or an empty string if
 * it doesn't exist.
 */
export const getCode = (code?: string | string[]): string => {
  if (!code) return '';

  // Unlikely but possible.
  if (Array.isArray(code)) return code[0];

  return code;
};

/**
 * @name useUrlParams
 * @description
 * We can't use the state to keep track of this because the queries that need
 * this info happen before the state is initialized.
 */
export const useUrlParams = (): UseUrlParamsResult => {
  const { query } = useRouter();

  const code = getCode(query.code);
  const viewDraft = query.draft === ADMIN_DRAFT ? true : false;
  const personaId =
    typeof query.personaId === 'string' && query.personaId !== ''
      ? String(query.personaId)
      : undefined;

  return {
    code,
    personaId,
    viewDraft
  };
};

/**
 * @name parseMultilineString
 * @description Our product descriptions are stored as a single non-html
 * string with "new lines" that we break up into an array which we use to
 * render a paragraph tag around each
 */
export const parseMultilineString = (description: string): string[] => {
  return description
    .replace(/( \r|\r)/g, '')
    .split('\n')
    .filter((str) => str.trim() !== '');
};

/**
 * @name getInitials
 * @description Splits a full name into a maximum of two initials. Names like
 * Jean Claude Van Damme will have the initials JC.
 */
export const getInitials = (fullName: string | undefined): string => {
  if (!fullName) return '';

  // const names = fullName.split(' ');
  const [first, last] = fullName.split(' ');
  const firstInitial = first.charAt(0).toUpperCase();
  const lastInitial = last?.charAt(0).toUpperCase() || '';

  return `${firstInitial}${lastInitial}`;
};

/**
 * @name getFileExtension
 * @description Extracts the file extension from a file name.
 */
export const getFileExtension = (filename: string) => {
  const ext = filename.match(/\.[0-9a-z]+$/i);

  if (!ext?.length) {
    throw new Error(`${filename} doesn't appear to be a valid file`);
  }

  return ext[0];
};

/**
 * @name trimCharacters
 * @description Clean up text inputs and trim ends.
 */
export function trimCharacters(name: string, type: string): string {
  if (!name) return '';

  if (type.includes('name')) {
    return name.replace(/[^a-z\s]/gi, '').trim();
  } else if (type.includes('email')) {
    return name.replace(/[\s]/g, '').toLowerCase();
  } else {
    return name.trim();
  }
}

import * as React from 'react';
import { color } from '@haldi/style-guide';
import classnames from 'classnames';

import { DiscountCodeResult } from '@src/generated/hooks';
import { useRoutes } from '@src/hooks/useRoutes';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';
import { IconShape } from '@src/routes/common/icons/IconShape';

export interface HasReferralsProps {
  code: DiscountCodeResult;
}

/**
 * @name HasReferrals
 * @description This component is shown when the user has referrals that have
 * completed the survey & successfully purchased a routine.
 */
export const HasReferrals = (props: HasReferralsProps) => {
  const { code } = props;

  // Setup
  const { amount, title } = code;
  const hasCredits = amount > 0;
  const copyIntro = hasCredits
    ? `You have <b>$${amount}</b> in Haldi credit. Use code <b>${title}</b> at checkout.`
    : `You have <b>$${amount}</b> in Haldi credit.`;
  const copyExit = hasCredits
    ? `Thanks so much for spreading the word!`
    : `Keep sharing - as a thank you, we’d like to give you free skincare.`;

  // Hooks
  const { isActive } = useRoutes();

  // Styles
  const cssCta = classnames(`mt-2x w-full`, { disabled: isActive.friends });

  // Markup
  const renderedImage = (
    <img alt="" className="my-2x" src="/images/refer-a-friend/products.jpg" />
  );

  const renderedStar = (
    <IconShape
      className="mb-1x"
      height={32}
      stroke={color.forest}
      strokeWidth={4}
      shape={13}
      width={32}
    />
  );

  return (
    <div className="w-full">
      {hasCredits ? renderedStar : renderedImage}
      <p dangerouslySetInnerHTML={{ __html: copyIntro }} />
      <p dangerouslySetInnerHTML={{ __html: copyExit }} />
      <HaldiButton className={cssCta} href="/profile/friends">
        {hasCredits ? 'View Tracker' : 'Remind me how'}
      </HaldiButton>
    </div>
  );
};

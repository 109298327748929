import * as React from 'react';
import { color } from '@haldi/style-guide';
import classnames from 'classnames';

import { DropdownTracker } from '@src/components/DropdownTracker';
import { useGetProfileFriendsQuery } from '@src/generated/hooks';
import { IconShape } from '@src/routes/common/icons/IconShape';
import { AppDropdown } from '@src/services/app/components/AppDropdown';

import styles from './styles.module.css';

export interface NavTrackerProps {
  className?: string;
}

/**
 * @name NavTracker
 * @description This component is used in the primary navigation bar and
 * provides information about the user's referrals and credits.
 */
export const NavTracker = (props: NavTrackerProps) => {
  const { className } = props;

  // Hooks
  const { data, error, loading } = useGetProfileFriendsQuery();

  // Styles
  const tailwind = 'flex h-full mr-1x items-center';
  const cssComponent = classnames(tailwind, styles.component, className);
  const cssStar = classnames('mr-1x', styles.star);

  // Markup
  const renderCredits = () => {
    const amount = credit.active;

    return (
      <div className="ml-1xs">
        My Credit:{' '}
        <span className="font-beatrice-medium text-turmeric">${amount}</span>
      </div>
    );
  };

  const renderPrompt = () => (
    <>
      <IconShape
        className={cssStar}
        fill={color.forest}
        height={16}
        shape={13}
        width={16}
      />{' '}
      Free Skincare
    </>
  );

  const renderContent = (_props: unknown) => {
    const amount = credit.active;
    const copy = amount > 0 ? renderCredits() : renderPrompt();

    return <div className={cssComponent}>{copy}</div>;
  };

  // 🔌 Short Circuit
  if (!data || error || loading) return null;

  const { friendsReferral } = data;
  const { credit } = friendsReferral;

  return (
    <AppDropdown
      className="h-full"
      content={renderContent}
      dropdown={DropdownTracker}
    />
  );
};

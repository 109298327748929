import '@src/config/sentry';

import * as React from 'react';
import { CookiesProvider } from 'react-cookie';
import { serialize } from 'cookie';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import parser from 'ua-parser-js';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

import { initializeApollo } from '@src/config/apollo';
import {
  COOKIE_AUTH_REFRESH,
  COOKIE_AUTH_TOKEN,
  COOKIE_OPTIONS,
  COOKIE_USER_ID,
  HEADER_USER_AGENT
} from '@src/config/constants';
import { IS_BROWSER } from '@src/config/settings';
import {
  GetUserIdDocument,
  GetUserIdQuery,
  GetUserIdQueryVariables
} from '@src/generated/hooks';
import { AppComponent } from '@src/services/app/components/AppComponent';

import '@src/styles/application.css';

export interface ApplicationProps extends AppProps {
  cookies: Record<string, any>;
  userAgent: parser.IResult;
}

/**
 * @name Application
 * @external https://nextjs.org/docs/advanced-features/custom-app
 * @description Next.js uses the App component to initialize pages.
 * You can override it and control the page initialization.
 */
export const Application = (props: ApplicationProps) => {
  const { cookies, Component, pageProps, userAgent } = props;

  // Setup
  const cookieData = new Cookies(cookies);
  const cookieProps = IS_BROWSER ? {} : { cookies: cookieData };

  return (
    <CookiesProvider {...cookieProps}>
      <AppComponent pageProps={pageProps} userAgent={userAgent}>
        <Component {...pageProps} />
      </AppComponent>
    </CookiesProvider>
  );
};

/**
 * Only uncomment this method if you have blocking data requirements for
 * every single page in your application. This disables the ability to
 * perform automatic static optimization, causing every page in your app to
 * be server-side rendered.
 *
 * We connect to our GraphQL instance, potentially, on any page. This means
 * that any of our `useQuery` or `useMutation` actions will execute on the
 * server side, so we have to make our cookies available to the server
 */
(Application as any).getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  const { req, res, query } = ctx;

  // Setup
  const cookies: Record<string, string | undefined> = IS_BROWSER
    ? document.cookie
    : (req as any)?.cookies ?? {};
  const token = cookies[COOKIE_AUTH_TOKEN];
  const refresh = cookies[COOKIE_AUTH_REFRESH];
  const uuid = cookies[COOKIE_USER_ID];
  const code = query.code ? (query.code as string) : undefined;
  const headerUA = req?.headers[HEADER_USER_AGENT] ?? '';
  const userAgent = parser(headerUA);

  // Set cookies on the server
  let userId = '';
  if (code || token) {
    const apollo = initializeApollo({ code, refresh, token });
    const { data } = await apollo.query<
      GetUserIdQuery,
      GetUserIdQueryVariables
    >({
      query: GetUserIdDocument
    });
    userId = data?.customerUserId.uuid ?? '';
  }

  let UUID = '';
  if (userId) {
    UUID = userId;
  } else if (uuid) {
    UUID = uuid;
  } else {
    UUID = uuidv4();
  }

  const cookie = serialize(COOKIE_USER_ID, UUID, COOKIE_OPTIONS);
  res?.setHeader('Set-Cookie', cookie);

  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps, cookies, userAgent };
};

export default Application;

import * as React from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import { useRecoilValue } from 'recoil';

import { SITE_SURVEY_PATH } from '@src/config/constants';
import { CLIENT_BASE_URL } from '@src/config/settings';
import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { useFirebase } from '@src/hooks/useFirebase';
import { useRoutes } from '@src/hooks/useRoutes';
import { useUrlParams } from '@src/hooks/useUrlParams';
import { userAtom } from '@src/recoil/atoms/user';
import { AppLink } from '@src/services/app/components/AppLink';

import styles from './styles.module.css';

export interface DropdownProfileProps {
  className?: string;
}

/**
 * @name DropdownProfile
 * @description The dropdown used in our "AppUserProfile" component
 */
export const DropdownProfile = (props: DropdownProfileProps) => {
  const { className } = props;

  // Hooks
  const { data } = useGetCustomerInfoQuery();
  const { isActive, isLoginLogout } = useRoutes();
  const { code } = useUrlParams();
  const { signOut } = useFirebase();
  const { visitor, email: emailLoggedIn, name } = useRecoilValue(userAtom);

  // Setup
  const created = data?.customerInfo?.created ?? false;
  const email = data?.customerInfo?.email ?? emailLoggedIn;
  const nameArray = name?.trim().split(' ') ?? [''];
  const firstName = data?.customerInfo?.firstName ?? nameArray[0];
  const lastName = data?.customerInfo?.lastName ?? nameArray[nameArray.length - 1]; // prettier-ignore
  const lifecycle = data?.customerInfo?.lifecycle ?? [];
  const isCustomer = lifecycle.includes('customer');
  const isVisitor = !code && visitor;

  const surveyParams = new URLSearchParams({
    email_param: email ?? '',
    f: firstName,
    l: lastName,
    purchased_param: isCustomer ? 'true' : ''
  });

  // Styles
  const active = styles.active;
  const tailwind = 'flex flex-col';
  const cssComponent = classnames(tailwind, styles.component, className);

  const cssRoutine = classnames({ [active]: isActive.routine });
  const cssStore = classnames({ [active]: isActive.store });
  const cssSummary = classnames({ [active]: isActive.summary });

  const cssAccount = classnames(styles.small, { [active]: isActive.account });
  const cssFriends = classnames(styles.small, { [active]: isActive.friends });
  const cssLogin = classnames(styles.small, { [active]: isLoginLogout });

  // Handlers
  const onClickLogout = async () => {
    signOut();
  };

  // Markup
  const renderedMyAccount = (
    <AppLink className={cssAccount} href="/profile/account">
      My account
    </AppLink>
  );
  const renderedLogin = (
    <AppLink className={cssLogin} href="/login">
      Log in
    </AppLink>
  );
  const renderedLogout = (
    <button className={styles.small} onClick={onClickLogout} type="button">
      Log out
    </button>
  );

  return (
    <div className={cssComponent}>
      <AppLink className={cssStore} href="/store?section=top">
        My store
      </AppLink>
      <AppLink className={cssRoutine} href="/profile/routine">
        My routine
      </AppLink>
      <AppLink className={cssSummary} href="/profile/summary">
        My skin profile
      </AppLink>

      <hr />

      <AppLink className={cssFriends} href="/profile/friends">
        Refer a friend
      </AppLink>
      <AppLink
        className={styles.small}
        href={`${CLIENT_BASE_URL}${SITE_SURVEY_PATH}?${surveyParams.toString()}`}
        target="_blank"
        rel="noreferrer"
      >
        Retake skin quiz
        <ArrowTopRightOnSquareIcon
          className="ml-1xs h-3 w-3 self-center"
          aria-hidden="true"
        />
      </AppLink>
      {created && renderedMyAccount}
      {!visitor && renderedLogout}
      {isVisitor && renderedLogin}
    </div>
  );
};

import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { uniqBy } from 'lodash';

import { SEARCH_DEBOUNCE_DELAY } from '@src/config/constants';
import {
  buildSearchFilterString,
  getAlgoliaClient,
  ProductHit
} from '@src/utils/algolia';

const cssLink = `no-underline text-black ml-1x font-beatrice-light text-14 md:text-16`;

/**
 * @name searchAllProducts
 * @description Search all products in our database, currently only used for clean-swap tool
 */
export const searchAllProducts = (onSelect: (id: string) => void) => {
  const searchClient = getAlgoliaClient();

  return createQuerySuggestionsPlugin<ProductHit>({
    indexName: 'products',
    searchClient,

    transformSource({ source, state }) {
      return {
        ...source,
        getItems: ({ query }) => {
          if (query.length < 3) return [];

          return getAlgoliaResults({
            queries: [
              {
                indexName: 'products',
                params: {
                  attributesToHighlight: ['full_name'],
                  attributesToRetrieve: ['full_name'],
                  filters: 'visible:yes AND NOT brand:your',
                  hitsPerPage: 6,
                  restrictSearchableAttributes: ['full_name']
                },
                query: state.query
              }
            ],
            searchClient,

            // Algolia has no type support for this function, use debugger to see response
            transformResponse: ({ hits }) => {
              const results = hits[0];

              if (!results.length) {
                results.push({
                  full_name: 'No results found.',
                  objectID: ''
                } as any);
              }

              return results;
            }
          });
        },
        onSelect: ({ item, setQuery }) => {
          onSelect(item.full_name);
          setQuery(item.full_name);
        },
        sourceId: 'products',
        templates: {
          ...source.templates,
          item({ item, components, html }) {
            return html`<div className="text-14 sm:text-16 ml-1xs">
              ${components.Highlight({
                attribute: 'full_name',
                hit: item,
                tagName: 'b'
              })}
            </div>`;
          }
        }
      };
    }
  });
};

/**
 * @name searchHaldiProducts
 * @description Creates and returns a plugin that can be used within
 * the user's store to search for Haldi products.
 */
export const searchHaldiProducts = (isPregnancy: boolean, code?: string) => {
  let timer: NodeJS.Timeout;

  const searchClient = getAlgoliaClient();

  return createQuerySuggestionsPlugin<ProductHit>({
    indexName: 'products',
    searchClient,
    transformSource({ source, state }) {
      return {
        ...source,
        getItems: ({ query }) => {
          if (query.length < 3) return [];

          return new Promise((resolve) => {
            if (timer) clearTimeout(timer);

            timer = setTimeout(() => {
              resolve(
                getAlgoliaResults({
                  queries: [
                    {
                      indexName: 'products',
                      params: {
                        attributesToHighlight: ['full_name'],
                        attributesToRetrieve: ['*'],
                        filters: buildSearchFilterString(isPregnancy),
                        hitsPerPage: 5,
                        restrictSearchableAttributes: [
                          'category_name',
                          'full_name'
                        ]
                      },
                      query: state.query
                    }
                  ],
                  searchClient,

                  // Algolia has no type support for this function, use debugger to see response
                  transformResponse: ({ hits }) => {
                    const results = hits[0];

                    if (!results.length) {
                      results.push({
                        full_name: 'No results found.',
                        objectID: ''
                      } as any);
                    }

                    return results;
                  }
                })
              );
            }, SEARCH_DEBOUNCE_DELAY);
          });
        },
        sourceId: 'products',
        templates: {
          ...source.templates,
          header({ html }) {
            return html`<span>Products</span>`;
          },
          item({ item, components, html }) {
            if (code !== undefined) {
              const href = `/store?code=${code}&product=${item.objectID}&section=discovery`;
              return html`<a className="${cssLink}" href="${href}">
                ${components.Highlight({
                  attribute: 'full_name',
                  hit: item,
                  tagName: 'b'
                })}
              </a>`;
            } else {
              const href = `/shop-mama?product=${item.objectID}`;
              return html`<a className="${cssLink}" href="${href}">
                ${components.Highlight({
                  attribute: 'full_name',
                  hit: item,
                  tagName: 'b'
                })}
              </a>`;
            }
          }
        }
      };
    }
  });
};

/**
 * @name searchHaldiBrands
 * @description Creates and returns a plugin that can be used within
 * the user's store to search for Haldi brands.
 */
export const searchHaldiBrands = (isPregnancy: boolean, code?: string) => {
  let timer: NodeJS.Timeout;

  const searchClient = getAlgoliaClient();

  return createQuerySuggestionsPlugin<ProductHit>({
    indexName: 'products',
    searchClient,
    transformSource({ source, state }) {
      return {
        ...source,
        getItems: ({ query }) => {
          if (query.length < 3) return [];

          return new Promise((resolve) => {
            if (timer) clearTimeout(timer);

            timer = setTimeout(() => {
              resolve(
                getAlgoliaResults({
                  queries: [
                    {
                      indexName: 'products',
                      params: {
                        attributesToHighlight: ['brand'],
                        attributesToRetrieve: ['*'],
                        filters: buildSearchFilterString(isPregnancy),
                        hitsPerPage: 20,
                        restrictSearchableAttributes: ['brand']
                      },
                      query: state.query
                    }
                  ],
                  searchClient,

                  transformResponse: ({ hits }) => {
                    const results = hits[0];

                    return uniqBy(results, 'brand');
                  }
                })
              );
            }, SEARCH_DEBOUNCE_DELAY);
          });
        },
        sourceId: 'brands',
        templates: {
          ...source.templates,
          header({ html }) {
            return html`<span>Brands</span>`;
          },
          item({ item, components, html }) {
            if (code !== undefined) {
              const href = `/store?code=${code}&section=discovery&brand=${item.brand}`;
              return html`<a className="${cssLink}" href="${href}">
                ${components.Highlight({
                  attribute: 'brand',
                  hit: item,
                  tagName: 'b'
                })}
              </a>`;
            } else {
              const href = `/shop-mama?brand=${item.brand}`;
              return html`<a className="${cssLink}" href="${href}">
                ${components.Highlight({
                  attribute: 'brand',
                  hit: item,
                  tagName: 'b'
                })}
              </a>`;
            }
          }
        }
      };
    }
  });
};

/**
 * @name searchHaldiCategories
 * @description Creates and returns a plugin that can be used within
 * the user's store to search for Haldi categories.
 */
export const searchHaldiCategories = (isPregnancy: boolean, code?: string) => {
  let timer: NodeJS.Timeout;

  const searchClient = getAlgoliaClient();

  return createQuerySuggestionsPlugin<ProductHit>({
    indexName: 'products',
    searchClient,
    transformSource({ source, state }) {
      return {
        ...source,
        getItems: ({ query }) => {
          if (query.length < 3) return [];

          return new Promise((resolve) => {
            if (timer) clearTimeout(timer);

            timer = setTimeout(() => {
              resolve(
                getAlgoliaResults({
                  queries: [
                    {
                      indexName: 'products',
                      params: {
                        attributesToHighlight: ['category_name'],
                        attributesToRetrieve: ['*'],
                        filters: buildSearchFilterString(isPregnancy),
                        hitsPerPage: 20,
                        restrictSearchableAttributes: ['category_name']
                      },
                      query: state.query
                    }
                  ],
                  searchClient,
                  transformResponse: ({ hits }) => {
                    const results = hits[0];

                    return uniqBy(results, 'category_name');
                  }
                })
              );
            }, SEARCH_DEBOUNCE_DELAY);
          });
        },
        sourceId: 'categories',
        templates: {
          ...source.templates,
          header({ html }) {
            return html`<span>Categories</span>`;
          },
          item({ item, components, html }) {
            if (code !== undefined) {
              const href = `/store?code=${code}&section=discovery&brand=all&category=${item.category}`;
              return html`<a className="${cssLink}" href="${href}">
                ${components.Highlight({
                  attribute: 'category_name',
                  hit: item,
                  tagName: 'b'
                })}
              </a>`;
            } else {
              const href = `/shop-mama?brand=all&category=${item.category}`;
              return html`<a className="${cssLink}" href="${href}">
                ${components.Highlight({
                  attribute: 'category_name',
                  hit: item,
                  tagName: 'b'
                })}
              </a>`;
            }
          }
        }
      };
    }
  });
};

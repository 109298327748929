import * as React from 'react';
import { color } from '@haldi/style-guide';
import classnames from 'classnames';

import {
  SITE_EMAIL_LINK,
  SITE_FACEBOOK,
  SITE_HALDI_MAMA_MISSION,
  SITE_INSTAGRAM,
  SITE_SURVEY_CTA,
  SITE_SURVEY_PATH,
  SITE_SURVEY_PREGNANCY_PATH
} from '@src/config/constants';
import { CLIENT_SHOPIFY_STORE } from '@src/config/settings';
import { useRoutes } from '@src/hooks/useRoutes';
import { AppLink } from '@src/services/app/components/AppLink';
import { NavLogo } from '@src/services/navigation/components/NavLogo';

import styles from './styles.module.css';

export interface NavFooterProps {}

/**
 * @name NavFooter
 * @description Site-wide footer element
 */
export const NavFooter = (_props: NavFooterProps) => {
  // Hooks
  const { isAcquisitionRoute, isAnonymousRoute, isLoginLogout } = useRoutes();

  // Styles
  const tailwind = 'flex flex-col sm:flex-row sm:gap-4x';
  const cssReflow = classnames(tailwind, styles.reflow);

  // Setup
  const { white } = color;
  const colors = new Array(10).fill(white);
  const year = new Date().getFullYear();
  const giftCardUrl = `https://${CLIENT_SHOPIFY_STORE}/products/gift-card`;
  const quizLink = isAnonymousRoute
    ? SITE_SURVEY_PREGNANCY_PATH
    : SITE_SURVEY_PATH;

  // Styles
  const cssComponent = classnames('ui-border-top bg-rust', styles.component);

  return (isAcquisitionRoute && !isLoginLogout) || isAnonymousRoute ? (
    <div className={cssComponent}>
      <footer className="ui-container-xl">
        <div className="flex flex-col gap-4x md:flex-row">
          <div className={styles.logo}>
            <NavLogo colors={colors} />
          </div>

          <div className={cssReflow}>
            <div className={styles.links}>
              <AppLink href="/about-us">Our Story</AppLink>
              {isAnonymousRoute && (
                <AppLink href={SITE_HALDI_MAMA_MISSION}>Our Mission</AppLink>
              )}
              {!isAnonymousRoute && (
                <AppLink href="/refer-a-friend">Free Skincare</AppLink>
              )}
              <AppLink href={quizLink}>{SITE_SURVEY_CTA}</AppLink>
              {!isAnonymousRoute && (
                <AppLink href="/brands">Our Brands</AppLink>
              )}
              <AppLink href={giftCardUrl}>Gift Cards</AppLink>
            </div>

            <div className={styles.links}>
              <a href={SITE_EMAIL_LINK} target="_blank" rel="noreferrer">
                Contact Us
              </a>
              <AppLink href="/sustainability">Sustainability</AppLink>
              {/* {!isAnonymousRoute && <AppLink href="/jobs">Jobs</AppLink>} */}
              <AppLink href="/legal/terms">Terms of Service</AppLink>
              <AppLink href="/legal/privacy">Privacy Policy</AppLink>
              <AppLink href="/faq">FAQs</AppLink>
            </div>
          </div>

          <div className={styles.social}>
            <div className="mb-2x flex flex-1 content-between md:mb-4x">
              <a
                className="mr-4x"
                href={SITE_INSTAGRAM}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="View Haldi on Instagram"
                  loading="lazy"
                  src="/images/svg/instagram.svg"
                />
              </a>
              <a
                className="mr-4x"
                href={SITE_FACEBOOK}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="View Haldi on Facebook"
                  loading="lazy"
                  src="/images/svg/facebook.svg"
                />
              </a>
            </div>
            <span className={styles.disclaimer}>
              All Rights Reserved {year}
            </span>
          </div>
        </div>
      </footer>
    </div>
  ) : null;
};

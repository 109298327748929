import * as React from 'react';

import { FriendsReferralResult } from '@src/generated/hooks';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';

export interface NoPurchasedProps {
  referrals: FriendsReferralResult;
}

/**
 * @name NoPurchased
 * @description Displayed when a user has had friends take the survey, but no
 * one has yet completed a purchase.
 */
export const NoPurchased = (_props: NoPurchasedProps) => {
  const { referrals } = _props;

  // Setup
  const { count } = referrals;

  return (
    <div>
      <img alt="" className="my-2x" src="/images/refer-a-friend/products.jpg" />
      <p>
        <b>{count.surveyed}</b> friends have completed the survey!
      </p>
      <p>
        You’ll receive credit as soon as one of them purchases their routine.
      </p>
      <HaldiButton className="mt-2x w-full" href="/profile/friends">
        View Tracker
      </HaldiButton>
    </div>
  );
};

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { autocomplete, AutocompletePlugin } from '@algolia/autocomplete-js';
import classnames from 'classnames';
import { useSetRecoilState } from 'recoil';

import { appAtom } from '@src/recoil/atoms/app';
import { ProductHit } from '@src/utils/algolia';

import styles from './styles.module.css';

export interface NavSearchProps {
  className?: string;
  brandPlugin: AutocompletePlugin<ProductHit, any>;
  suggestionPlugin: AutocompletePlugin<ProductHit, any>;
  categoryPlugin: AutocompletePlugin<ProductHit, any>;
}

/**
 * @name NavSearch
 * @description Dynamic search bar to search haldi products
 * @external https://www.algolia.com/doc/ui-libraries/autocomplete/guides/creating-a-multi-column-layout/
 */
export const NavSearch = (props: NavSearchProps) => {
  const { className, suggestionPlugin, brandPlugin, categoryPlugin } = props;

  // Hooks
  const containerRef = React.useRef<any>(null);
  const panelRootRef = React.useRef<any>(null);
  const rootRef = React.useRef<any>(null);
  const autoCompleteRef = React.useRef<any>(null);
  const setTyping = useSetRecoilState(appAtom);

  //Handlers

  // Styles
  const tailwind = 'flex items-center w-full h-[70px]';
  const cssComponent = classnames(tailwind, styles.component, className);

  // Markup
  React.useEffect(() => {
    if (!containerRef.current) return undefined;

    autoCompleteRef.current = autocomplete({
      autoFocus: true,
      classNames: {
        form: 'bg-transparent',
        input: 'pl-8 p-0 border-none bg-transparent',
        panel: 'border-none max-h-[320px]',
        panelLayout: 'mt-3 md:ml-4 lg:ml-6 mb-0 pb-0'
      },
      container: containerRef.current,

      // Suppress mobile default behavior
      detachedMediaQuery: 'none',
      onStateChange: ({ state }) => {
        const query = state.query;
        setTyping((prevState) => ({
          ...prevState,
          typing: query?.length > 0
        }));
      },
      placeholder: 'What are you looking for?',
      plugins: [categoryPlugin, brandPlugin, suggestionPlugin],

      // React necessary fixes: https://www.algolia.com/doc/ui-libraries/autocomplete/integrations/using-react/
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }
        panelRootRef.current.render(children);
      },
      renderer: {
        Fragment: React.Fragment,
        createElement: React.createElement,

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        render: () => {}
      }
    });
    return () => {
      autoCompleteRef.current.destroy();
    };
  }, [props]);

  return (
    <div className={cssComponent}>
      <div className="w-full bg-transparent" ref={containerRef} />
    </div>
  );
};

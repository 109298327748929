import {
  categoryToName,
  categoryToPriority,
  CollectionSectionType,
  HIDDEN_LABELS,
  SECTION_MAP,
  SECTION_PRIORITY_MAP,
  Skintype,
  SKINTYPES
} from '@src/config/maps';
import {
  CartProduct,
  CollectionCategoryType,
  CollectionProductVariantStatus,
  GetCustomerCartQuery,
  PersonaStoreProductVariant,
  RecommendedLabel,
  StoreIndexProductFieldsFragment
} from '@src/generated/hooks';

export type StoreCategory = {
  name: string;
  category: CollectionSectionType | CollectionCategoryType | 'all';
  priority: number;
  subCategories: StoreCategoryMap;
  depth: number;
};

export type StoreCategoryMap = {
  [key: string]: StoreCategory;
};

/**
 * @name parseSkintypes
 * @description Returns a readable formatted string of skintypes.
 */
export const parseSkintypes = (skintype: string) => {
  const skintypes: string[] = [];

  for (const skin of SKINTYPES) {
    if (skintype.includes(skin.id)) {
      skintypes.push(skin.name);
    }
  }

  // If all skintypes are represented, return 'All'
  if (skintypes.length >= 4) return 'All';

  return skintypes.join(', ');
};

/**
 * @name getStoreCategoriesMapSorted
 * @description Returns a sorted array of store categories.
 */
export const getStoreCategoriesMapSorted = (
  categories: StoreCategoryMap
): StoreCategory[] => {
  const sorted = Object.values(categories).sort((a, b) => {
    return a.priority - b.priority;
  });

  return sorted;
};

/**
 * @name addSection
 * @description Does the following to a section (root level story menu node).
 * 1. Create an 'all' category if it doesn't exist on the root level
 * 2. Do the same for the next level down which is the category. It will
 *    match the category name and use that.
 */
export const addSection = (
  product: StoreIndexProductFieldsFragment,
  section: StoreCategory
) => {
  // Create the "all" subcategory if it doesn't exist.
  if (!section.subCategories.all) {
    section.subCategories.all = {
      category: 'all',
      depth: section.depth + 1,
      name: 'All',
      priority: 0,
      subCategories: {}
    };
  }

  // Create the category if it doesn't exist.
  if (!section.subCategories[product.category]) {
    section.subCategories[product.category] = {
      category: product.category,
      depth: section.depth + 1,
      name: categoryToName(product.category),
      priority: categoryToPriority(product.category),
      subCategories: {}
    };
  }
};

/**
 * @name addCategory
 */
export const addCategory = (
  product: StoreIndexProductFieldsFragment,
  categories: StoreCategoryMap
) => {
  // Create the "all" category if it doesn't exist.
  if (!categories.all) {
    categories.all = {
      category: 'all',
      depth: 0,
      name: 'All',
      priority: 0,
      subCategories: {}
    };
  }

  // Create the category if it doesn't exist.
  if (!categories[product.category]) {
    categories[product.category] = {
      category: product.category,
      depth: 0,
      name: categoryToName(product.category),
      priority: categoryToPriority(product.category),
      subCategories: {}
    };
  }
};

/**
 * @name buildCategoryTree
 * @description This utility builds out the category tree for the store to show
 * all the menus and what sits under each menu.
 */
export const buildCategoryTree = (
  products: StoreIndexProductFieldsFragment[]
): StoreCategoryMap => {
  // The root level categories we'll have in every store.
  const categories: StoreCategoryMap = {
    discovery: {
      category: 'discovery',
      depth: 0,
      name: SECTION_MAP['discovery'],
      priority: SECTION_PRIORITY_MAP['discovery'],
      subCategories: {}
    },
    holiday: {
      category: 'holiday',
      depth: 0,
      name: SECTION_MAP['holiday'],
      priority: SECTION_PRIORITY_MAP['holiday'],
      subCategories: {}
    },
    purchased: {
      category: 'purchased',
      depth: 0,
      name: SECTION_MAP['purchased'],
      priority: SECTION_PRIORITY_MAP['purchased'],
      subCategories: {}
    },
    top: {
      category: 'top',
      depth: 0,
      name: SECTION_MAP['top'],
      priority: SECTION_PRIORITY_MAP['top'],
      subCategories: {}
    }
  };

  /**
   * Loop through all the products and build out the category tree. Each leaf
   * node needs to also exist in a special subcategory called "all" so so that
   * we don't need to traverse the tree each time to find all products under lets
   * say Exfoliator, when it has multiple subcategories.
   */
  let hasTopPicks = false;
  let hasPurchased = false;
  let hasHoliday = false;
  products.forEach((product) => {
    if (!HIDDEN_LABELS.includes(product.recommended.id)) {
      // Discovery includes all non-hidden products
      addSection(product, categories.discovery);

      if (product.recommended.id === 'top') {
        hasTopPicks = true;
        addSection(product, categories.top);
      }

      if (product.purchased > 0) {
        hasPurchased = true;
        addSection(product, categories.purchased);
      }

      if (product.category === 'holiday_store') {
        hasHoliday = true;
      }
    }
  });

  // Filter out empty categories
  if (!hasTopPicks) delete categories.top;
  if (!hasPurchased) delete categories.purchased;
  if (!hasHoliday) delete categories.holiday;

  return categories;
};

/**
 * @name buildShopCategoryTree
 * @description This utility builds out the category tree for the store to show
 * all the menus and what sits under each menu.
 */
export const buildShopCategoryTree = (
  products: StoreIndexProductFieldsFragment[]
): StoreCategoryMap => {
  // All qualified categories from haldi (discovery only)
  const categories: StoreCategoryMap = {};

  products.forEach((product) => {
    // Root level is product category (control logic through API)
    addCategory(product, categories);
  });

  return categories;
};

/**
 * @name filterBySection
 * @description When we fetch a users store, we grab all their products
 * at once, however we allow them to filter down by category so we do so
 * by filtering the already fetched products down
 */
export const filterBySection = (
  products: StoreIndexProductFieldsFragment[],
  section?: CollectionSectionType,
  category?: CollectionCategoryType | 'all',
  brand?: string | 'all',
  productId?: string
): StoreIndexProductFieldsFragment[] => {
  const isTop = section === 'top';
  const isPurchased = section === 'purchased';
  const isHoliday = section === 'holiday';

  // Early out - Single product view
  if (productId) {
    return products.filter((product) => product.id === productId);
  }

  if (isTop) {
    return products.filter(
      (product) =>
        (product.category === category || category === 'all') &&
        product.recommended.id === 'top'
    );
  }

  if (isPurchased) {
    return products.filter(
      (product) =>
        product.purchased > 0 &&
        (product.category === category || category === 'all') &&
        !HIDDEN_LABELS.includes(product.recommended.id)
    );
  }

  if (isHoliday) {
    return products.filter(
      (product) =>
        product.category === 'holiday_store' &&
        !HIDDEN_LABELS.includes(product.recommended.id)
    );
  }

  // Discovery store (brand filter is used here)
  return products.filter((product) => {
    const isHidden = HIDDEN_LABELS.includes(product.recommended.id);
    const hasBrand = !!brand && brand !== 'all'; // Overrides category filter
    const matchBrand = hasBrand ? product.brand === brand : true;
    const matchCategory = !hasBrand
      ? product.category === category || category === 'all'
      : true;

    return !isHidden && matchCategory && matchBrand;
  });
};

/**
 * @name filterByShopSection
 * @description When we fetch haldi products, we grab all products
 * at once, however we allow them to filter down by category so we do so
 * by filtering the already fetched products down
 */
export const filterByShopSection = (
  products: StoreIndexProductFieldsFragment[],
  skintype: Skintype,
  category?: CollectionCategoryType | 'all',
  brand?: string | 'all',
  productId?: string
): StoreIndexProductFieldsFragment[] => {
  // Early out - Single product view
  if (productId) {
    return products.filter((product) => product.id === productId);
  }

  return products.filter((product) => {
    const hasBrand = !!brand && brand !== 'all'; // Overrides category filter

    const isSkintype =
      skintype !== 'all' ? product.skintype.includes(skintype) : true;
    const isBrand = hasBrand ? product.brand === brand : true;
    const isCategory = !hasBrand
      ? product.category === category || category === 'all'
      : true;

    return isSkintype && isCategory && isBrand;
  });
};

/**
 * @name sortByPriceAsc
 * @description This utility to rank low -> high
 */
export const sortByPriceAsc = (
  a: StoreIndexProductFieldsFragment,
  b: StoreIndexProductFieldsFragment
): number => {
  return a.price - b.price;
};

/**
 * @name sortByPriceDesc
 * @description This utility to rank high -> low
 */
export const sortByPriceDesc = (
  a: StoreIndexProductFieldsFragment,
  b: StoreIndexProductFieldsFragment
): number => {
  return b.price - a.price;
};

/**
 * @name sortByBestSeller
 * @description This utility to rank by rating high -> low
 */
export const sortByBestSeller = (
  a: StoreIndexProductFieldsFragment,
  b: StoreIndexProductFieldsFragment
): number => {
  return b.rating - a.rating || a.priority - b.priority;
};

/**
 * @name sortByCreated
 * @description This utility to rank by created date
 */
export const sortByCreated = (
  a: StoreIndexProductFieldsFragment,
  b: StoreIndexProductFieldsFragment
): number => {
  const aTime = new Date(a.created).getTime();
  const bTime = new Date(b.created).getTime();

  return bTime - aTime;
};

/**
 * @name sortByNameAsc
 * @description This utility to rank by product name
 */
export const sortByNameAsc = (
  a: StoreIndexProductFieldsFragment,
  b: StoreIndexProductFieldsFragment
): number => {
  return a.title.localeCompare(b.title);
};

/**
 * @name sortByBrandAsc
 * @description This utility to rank by brand
 */
export const sortByBrandAsc = (
  a: StoreIndexProductFieldsFragment,
  b: StoreIndexProductFieldsFragment
): number => {
  return a.brand.localeCompare(b.brand);
};

/**
 * @name sortByPurchased
 * @description This utility to rank previously purchased products by most recent
 */
export const sortByPurchased = (
  a: StoreIndexProductFieldsFragment,
  b: StoreIndexProductFieldsFragment
): number => {
  return (
    b.purchasedDate.localeCompare(a.purchasedDate) || a.priority - b.priority
  );
};

/**
 * @name sortByRanking
 * @description This utility to rank our products by recommendation status
 */
export const sortByRanking = (
  a: StoreIndexProductFieldsFragment,
  b: StoreIndexProductFieldsFragment
): number => {
  const order: RecommendedLabel[] = [
    'top',
    'great',
    'discovery',
    'splurge',
    'budget',
    'may_fit',
    'not_good',
    'not_pregnancy',
    'unknown',
    'no_inventory'
  ];

  const aIndex = order.indexOf(a.recommended.id) + 1;
  const bIndex = order.indexOf(b.recommended.id) + 1;
  const aPriority = a.priority + 1;
  const bPriority = b.priority + 1;

  const aWeighted =
    10000000 / aIndex +
    100000 / aPriority +
    100 * a.purchased +
    a.recommended.score / 100;
  const bWeighted =
    10000000 / bIndex +
    100000 / bPriority +
    100 * b.purchased +
    b.recommended.score / 100;

  return bWeighted - aWeighted || a.title.localeCompare(b.title);
};

/**
 * @name getRankingTextCss
 * @description Find the correct text color for each ranking combination.
 */
export const getRankingTextCss = (id: RecommendedLabel) => {
  switch (id) {
    case 'top':
      return 'text-pine';
    case 'great':
      return 'text-great';
    case 'discovery':
    case 'splurge':
      return 'text-good';
    case 'may_fit':
    case 'budget':
      return 'text-may';
    case 'no_inventory':
    case 'not_pregnancy':
    case 'not_good':
      return 'text-not';
    default:
      return '';
  }
};

/**
 * @name getRankingBackgroundCss
 * @description Find the correct background color for each ranking combination.
 */
export const getRankingBackgroundCss = (id: RecommendedLabel) => {
  switch (id) {
    case 'top':
      return 'bg-pine';
    case 'great':
      return 'bg-great';
    case 'discovery':
    case 'splurge':
      return 'bg-good';
    case 'may_fit':
      return 'bg-may';
    case 'budget':
      return 'bg-good';
    case 'no_inventory':
    case 'not_pregnancy':
    case 'not_good':
      return 'bg-not';
    default:
      return '';
  }
};

/**
 * @name parseProductIngredients
 * @description We need to massage our ingredients to be more readable
 */
export const parseProductIngredients = (ingredients: string): string => {
  const parts = ingredients.split(',');
  const trimmed = parts.map((part) => part.trim());
  const filtered = trimmed.filter((part) => part !== '');
  const results = filtered.join(', ');

  return results;
};

export interface HighLightedIngredientString {
  text: string;
  highlight: boolean;
  slideIndex?: number;
}

/**
 * @name getHighlightedIngredientList
 * @desc We need to highlight the ingredients that are offenders. We do this
 * by looping through the ingredient list and splitting it into pre-highlight,
 * matched offender, and post-highlight strings.
 */
export const getHighlightedIngredientList = (
  ingredients: string,
  matches?: string[]
): HighLightedIngredientString[] => {
  if (!matches?.length) return [{ highlight: false, text: ingredients }];

  const ret: HighLightedIngredientString[] = [];
  let remaining = ingredients;

  for (let i = 0; i < matches.length; i++) {
    const searchString = matches[i];
    const foundIndex = remaining.indexOf(searchString);
    if (foundIndex === -1) continue;

    const before = remaining.slice(0, foundIndex);
    const after = remaining.slice(foundIndex + searchString.length);

    // Push the string that comes before the match.
    if (before.length) {
      ret.push({
        highlight: false,
        text: before
      });
    }

    // Push the match.
    ret.push({
      highlight: true,
      slideIndex: i,
      text: searchString
    });

    remaining = after;
  }

  // Push the remaining string.
  if (remaining.length) {
    ret.push({
      highlight: false,
      text: remaining
    });
  }

  return ret;
};

/**
 * @name getVariantStatusText
 * @description Returns the text for the variant status if it's not ok.
 */
export const getVariantStatusText = (
  status: CollectionProductVariantStatus
): string => {
  if (status === 'back_ordered') return 'Back ordered - will ship next week';
  else if (status === 'back_ordered_long') return 'Back ordered';
  else if (status === 'out_of_stock') return 'Out of stock';

  return '';
};

/**
 * @name getFirstSelectableVariant
 * @description Returns the first selectable variant or a default variant so that
 * no existing variant is selected.
 */
export const getFirstSelectableVariant = (
  variants: StoreIndexProductFieldsFragment['variants']
): PersonaStoreProductVariant => {
  const first = variants.find((variant) => variant.status !== 'out_of_stock');
  return first ?? { id: '0', name: '', price: 0, status: 'ok' };
};

/**
 * @name getCartTotal
 * @description Returns the total price of the cart.
 */
export const getCartTotal = (
  cart?: GetCustomerCartQuery['customerCart']
): number => {
  const reducer = (accumulator: number, currentValue: CartProduct) => {
    const { price, quantity } = currentValue;
    const value = price * quantity;

    return accumulator + value;
  };

  const total = cart?.products?.reduce(reducer, 0) ?? 0;
  return total;
};

/**
 * @name getCartTotalForGift
 * @description Returns the total price of the cart (ignore gift cards)
 */
export const getCartTotalForGift = (
  cart?: GetCustomerCartQuery['customerCart']
): number => {
  const reducer = (accumulator: number, currentValue: CartProduct) => {
    const { price, quantity, name } = currentValue;

    // Ignore products with the word 'Gift' in their title
    if (name.toLowerCase().includes('gift')) return accumulator;

    const value = price * quantity;
    return accumulator + value;
  };

  const total = cart?.products?.reduce(reducer, 0) ?? 0;
  return total;
};

/**
 * @name shouldShowGiftWithPurchase
 * @description Returns true if we should show any gift with purchase components
 * based on this customer.
 */
export const shouldShowGiftWithPurchase = ({
  isCustomer,
  isPregnancy,
  isPregnancySafe,
  giftWithPurchaseStatus
}: {
  isCustomer: boolean;
  isPregnancy?: boolean;
  isPregnancySafe?: boolean;
  giftWithPurchaseStatus?: string;
}) => {
  const validLeadStatuses = ['leads', 'both'];
  const validCustomerStatuses = ['customers', 'both'];
  const validStatuses = ['leads', 'customers', 'both'];

  if (
    // Disqualify GWP if not a valid status
    !giftWithPurchaseStatus ||
    !validStatuses.includes(giftWithPurchaseStatus)
  ) {
    return false;
  } else if (isPregnancy && !isPregnancySafe) {
    // Disqualify GWP if user is pregnancy, and GWP is not safe for them
    return false;
  } else {
    if (!isCustomer && validLeadStatuses.includes(giftWithPurchaseStatus)) {
      return true;
    } else if (
      isCustomer &&
      validCustomerStatuses.includes(giftWithPurchaseStatus)
    ) {
      return true;
    }
  }

  return false;
};

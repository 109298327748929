import { CollectionCategoryType, RecommendedLabel } from '@src/generated/hooks';

export type CollectionSectionType =
  | 'top'
  | 'purchased'
  | 'discovery'
  | 'holiday';

export const CATEGORY_MAP: Record<CollectionCategoryType, string> = {
  acne: 'Acne',
  body: 'Body',
  cleanser: 'Cleanser',
  exfoliator: 'Exfoliator',
  eye_care: 'Eye Care',
  face_oil: 'Face Oil',
  face_scrub: 'Face Scrub',
  holiday_store: 'Gifts & Sets',
  mask: 'Mask',
  moisturizer: 'Moisturizer',
  other: 'This and That',
  retinol: 'Retinol',
  serum: 'Serum',
  sunscreen: 'Sunscreen',
  toner_hydrator: 'Toner / Hydrator'
} as const;

export const CATEGORY_PLURAL: Record<CollectionCategoryType, string> = {
  acne: 'acne treatments',
  body: 'body products',
  cleanser: 'cleansers',
  exfoliator: 'exfoliators',
  eye_care: 'eye care products',
  face_oil: 'face oils',
  face_scrub: 'face scrubs',
  holiday_store: 'gifts & sets',
  mask: 'masks',
  moisturizer: 'moisturizers',
  other: 'accessories',
  retinol: 'retinols',
  serum: 'serums',
  sunscreen: 'sunscreens',
  toner_hydrator: 'hydrators'
} as const;

// Make sure admin map is in sync with this!
export const CATEGORY_TITLE_PLURAL: Record<CollectionCategoryType, string> = {
  acne: 'Acne Treatments',
  body: 'Body',
  cleanser: 'Cleansers',
  exfoliator: 'Exfoliators',
  eye_care: 'Eye Care',
  face_oil: 'Face Oils',
  face_scrub: 'Face Scrubs',
  holiday_store: 'Gifts & Sets',
  mask: 'Masks',
  moisturizer: 'Moisturizers',
  other: 'Accessories',
  retinol: 'Retinols',
  serum: 'Serums',
  sunscreen: 'Sunscreens',
  toner_hydrator: 'Toners & Hydrators'
} as const;

export const CATEGORY_PRIORITY_MAP: Record<CollectionCategoryType, number> = {
  acne: 7,
  body: 12,
  cleanser: 0,
  exfoliator: 1,
  eye_care: 8,
  face_oil: 5,
  face_scrub: 11,
  holiday_store: 13,
  mask: 10,
  moisturizer: 6,
  other: 14,
  retinol: 4,
  serum: 3,
  sunscreen: 9,
  toner_hydrator: 2
} as const;

export const HIDDEN_LABELS: RecommendedLabel[] = ['not_good', 'not_pregnancy'];

export const INTENSITY_LEVEL: Record<string, string> = {
  high: 'Strong',
  low: 'Light',
  medium: 'Moderate'
} as const;

export type ProductSort =
  | 'rating'
  | 'created'
  | 'price-asc'
  | 'price-desc'
  | 'brand-asc'
  | 'name-asc';

export const PRODUCT_SORT: { id: ProductSort; name: string }[] = [
  { id: 'rating', name: 'Best Selling' },
  { id: 'brand-asc', name: 'Brand: A-Z' },
  { id: 'name-asc', name: 'Name: A-Z' },
  { id: 'created', name: 'Newest' },
  { id: 'price-asc', name: 'Price: Low to High' },
  { id: 'price-desc', name: 'Price: High to Low' }
] as const;

export type Skintype = 'all' | 'dry' | 'normal' | 'combination' | 'oily';

export const SKINTYPES: { id: Skintype; name: string }[] = [
  { id: 'all', name: 'All' },
  { id: 'dry', name: 'Dry' },
  { id: 'normal', name: 'Normal' },
  { id: 'combination', name: 'Combination' },
  { id: 'oily', name: 'Oily' }
] as const;

export const SECTION_MAP: Record<CollectionSectionType, string> = {
  discovery: 'Discovery Store',
  holiday: 'Gifts & Sets',
  purchased: 'Purchased',
  top: 'Top Picks'
} as const;

export const SECTION_PRIORITY_MAP: Record<CollectionSectionType, number> = {
  discovery: 2,
  holiday: 1,
  purchased: 3,
  top: 0
} as const;

//////////////// Functions ////////////////////

export const categoryToName = (category: CollectionCategoryType | string) => {
  if (category === 'all') return 'All';
  return CATEGORY_MAP[category as CollectionCategoryType];
};

export const categoryToPriority = (
  category: CollectionCategoryType | string
) => {
  return CATEGORY_PRIORITY_MAP[category as CollectionCategoryType];
};

export const sectionToName = (section: CollectionSectionType) => {
  return SECTION_MAP[section];
};

export const sectionToPriority = (section: CollectionSectionType | string) => {
  return SECTION_PRIORITY_MAP[section as CollectionSectionType];
};

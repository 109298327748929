import * as React from 'react';
import classnames from 'classnames';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { SITE_SURVEY_PATH } from '@src/config/constants';
import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { useRoutes } from '@src/hooks/useRoutes';
import { useUrlParams } from '@src/hooks/useUrlParams';
import { appAtom } from '@src/recoil/atoms/app';
import { userAtom } from '@src/recoil/atoms/user';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';
import { AppLink } from '@src/services/app/components/AppLink';
import { NavCart } from '@src/services/navigation/components/NavCart';
import { NavLogo } from '@src/services/navigation/components/NavLogo';
import { NavProfile } from '@src/services/navigation/components/NavProfile';
import { NavTracker } from '@src/services/navigation/components/NavTracker';
import {
  searchHaldiBrands,
  searchHaldiCategories,
  searchHaldiProducts
} from '@src/utils/algolia-search';
import { NavLogoMama } from '../NavLogoMama/NavLogoMama';
import { NavSearch } from '../NavSearch/NavSearch';

import styles from './navStoreDesktop.module.css';

export interface NavStoreDesktopProps {
  className?: string;
}

/**
 * @name NavStoreDesktop
 * @description Very simple navigation bar we want across the application
 */
export const NavStoreDesktop = (props: NavStoreDesktopProps) => {
  const { className } = props;

  // Hooks
  const navRef = React.useRef<HTMLElement>(null);
  const { isActive, isLoginLogout } = useRoutes();
  const { code } = useUrlParams();
  const { visitor } = useRecoilValue(userAtom);
  const { data, loading } = useGetCustomerInfoQuery();
  const [isSearching, setIsSearching] = React.useState(false);
  const setBlur = useSetRecoilState(appAtom);

  // Setup
  const isPregnancy = data?.customerInfo?.pregnancy ?? false;
  const isVisitor = !code && visitor;
  const isStorePage = isActive.store;

  // Handlers
  const handleSearchClick = () => {
    setIsSearching(!isSearching);
  };
  const handleSearchBlur = () => {
    setIsSearching(false);
    setBlur((state) => ({ ...state, typing: false }));
  };

  // Styles
  const cssComponent = classnames(styles.component, className);
  const cssLogin = classnames('flex items-center', styles.link);
  const cssGetRoutine = classnames(
    'self-center flex ml-2x px-6x h-[50px] min-w-[210px]',
    styles.routine
  );

  // Markup
  const renderLogin = () => {
    if (!isVisitor || isLoginLogout) return null;

    return (
      <AppLink className={cssLogin} href="/login">
        Login
      </AppLink>
    );
  };

  const renderGetRoutineLink = () => {
    if (!isVisitor || isLoginLogout) return null;

    return (
      <HaldiButton className={cssGetRoutine} href={SITE_SURVEY_PATH}>
        Get your routine
      </HaldiButton>
    );
  };

  const renderLogo = () => {
    if (loading) return null;

    return isPregnancy ? (
      <NavLogoMama className="mx-1x my-2xs max-w-[200px] p-2xs" />
    ) : (
      <NavLogo className={classnames('mx-6x mt-1 self-center', styles.logo)} />
    );
  };

  return (
    <nav className={cssComponent} ref={navRef}>
      <div className="ui-container-xl align-center h-full w-full items-center">
        <div className="absolute top-0 left-0 w-full">
          <div className="flex h-[70px] w-full">
            {renderLogo()}
            {!isVisitor && isSearching && isStorePage && (
              <div
                className="relative flex h-[70px] w-full items-center"
                onBlur={handleSearchBlur}
              >
                <img
                  alt="Magnifying glass"
                  className="w-4"
                  src="/icons/magnifier.svg"
                />
                <NavSearch
                  className="absolute left-0"
                  suggestionPlugin={searchHaldiProducts(isPregnancy, code)}
                  brandPlugin={searchHaldiBrands(isPregnancy, code)}
                  categoryPlugin={searchHaldiCategories(isPregnancy, code)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="absolute right-0 top-0">
          <div className="flex h-[70px] bg-white">
            {renderLogin()}
            {!isVisitor && isStorePage && (
              <span
                className={`mr-1xs flex h-full items-center font-beatrice-regular text-14 hover:cursor-pointer`}
                onClick={handleSearchClick}
              >
                {isSearching ? (
                  <img
                    alt="Close"
                    width="20"
                    height="20"
                    src="/images/svg/close.svg"
                  />
                ) : (
                  <span>
                    <img
                      alt="Magnifying glass"
                      className="mr-1xs w-4 align-middle"
                      src="/icons/magnifier.svg"
                    />
                    Search
                  </span>
                )}
              </span>
            )}

            {!isVisitor && <NavTracker className="mx-2x min-w-max" />}
            {!isVisitor && <NavProfile className="px-2x" />}
            {!isVisitor && <NavCart className="mr-2x" />}
            {renderGetRoutineLink()}
          </div>
        </div>
      </div>
    </nav>
  );
};

import * as React from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue } from 'recoil';

import { SITE_SURVEY_PATH } from '@src/config/constants';
import { sectionToName } from '@src/config/maps';
import { CLIENT_BASE_URL } from '@src/config/settings';
import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { useFirebase } from '@src/hooks/useFirebase';
import { useUrlParams } from '@src/hooks/useUrlParams';
import { mobileAtom } from '@src/recoil/atoms/mobile';
import { userAtom } from '@src/recoil/atoms/user';
import { AppLink } from '@src/services/app/components/AppLink';

import styles from './styles.module.css';

export interface NavMobileStoreProps {}

/**
 * @name NavMobileStore
 * @description This component is similar to our modal, it utilizes fixed
 * positioning and visibility is controlled by a hamburger button. This
 * route is shown on store pages
 */
export const NavMobileStore = (_props: NavMobileStoreProps) => {
  // Hooks
  const [mobile, setMobile] = useRecoilState(mobileAtom);
  const { data } = useGetCustomerInfoQuery();
  const { code } = useUrlParams();
  const { pathname } = useRouter();
  const { signOut } = useFirebase();
  const { visitor, email: emailLoggedIn, name } = useRecoilValue(userAtom);

  // Setup
  const { open } = mobile;
  const created = data?.customerInfo?.created ?? false;
  const email = data?.customerInfo?.email ?? emailLoggedIn;
  const nameArray = name?.trim().split(' ') ?? [''];
  const firstName = data?.customerInfo?.firstName ?? nameArray[0];
  const lastName = data?.customerInfo?.lastName ?? nameArray[nameArray.length - 1]; // prettier-ignore
  const lifecycle = data?.customerInfo?.lifecycle ?? [];
  const isCustomer = lifecycle.includes('customer');
  const isVisitor = !code && visitor;

  const surveyParams = new URLSearchParams({
    email_param: email ?? '',
    f: firstName,
    l: lastName,
    purchased_param: isCustomer ? 'true' : ''
  });

  // Styles
  const tailwind = `flex flex-col ui-mobile gap-3xs mt-10`;
  const cssContent = classnames(tailwind, styles.content);
  const cssComponent = classnames('px-3xs py-5x', styles.component, {
    [styles.open]: open
  });
  const cssSurvey = classnames(styles.link);
  const getClasses = (path: string, uppercase = false, small = false) => {
    return classnames(small ? styles.small : styles.link, {
      [styles.active]: pathname === path,
      [styles.uppercase]: uppercase
    });
  };

  // Handlers
  const onClickClose = () => {
    setMobile((state) => ({ ...state, open: false }));
  };

  const onClickLogout = async () => {
    signOut();
    onClickClose();
  };

  // Markup
  const renderedMyAccount = (
    <AppLink
      className={getClasses('/profile/account')}
      href="/profile/account"
      onClick={onClickClose}
    >
      My account
    </AppLink>
  );
  const renderedLogin = (
    <AppLink className={getClasses('/login')} href="/login">
      Log in
    </AppLink>
  );
  const renderedLogout = (
    <button
      className={getClasses('/login')}
      onClick={onClickLogout}
      type="button"
    >
      Log out
    </button>
  );

  return (
    <div className={cssComponent}>
      <button className={styles.close} onClick={onClickClose}>
        <img alt="Close navigation" src="/images/svg/close.svg" />
      </button>

      <div className={cssContent}>
        <AppLink
          className={getClasses('/store', true)}
          href="/store?section=top"
          onClick={onClickClose}
        >
          My store
        </AppLink>

        {/* Begin store links */}
        <div className="ml-6 flex flex-col gap-4">
          <AppLink
            className={getClasses('/store', false, true)}
            href="/store?section=top&category=all&brand=all"
            onClick={onClickClose}
          >
            {sectionToName('top')}
          </AppLink>
          <AppLink
            className={getClasses('/store', false, true)}
            href="/store?section=discovery&category=all&brand=all"
            onClick={onClickClose}
          >
            {sectionToName('discovery')}
          </AppLink>
          {isCustomer && (
            <AppLink
              className={getClasses('/store', false, true)}
              href="/store?section=purchased&category=all&brand=all"
              onClick={onClickClose}
            >
              {sectionToName('purchased')}
            </AppLink>
          )}
        </div>
        {/* End store links */}

        <AppLink
          className={getClasses('/profile/routine', true)}
          onClick={onClickClose}
          href="/profile/routine"
        >
          My routine
        </AppLink>
        <AppLink
          className={getClasses('/profile/summary', true)}
          href="/profile/summary"
          onClick={onClickClose}
        >
          My skin profile
        </AppLink>

        <hr className="my-2x" />

        <AppLink
          className={getClasses('/profile/friends')}
          href="/profile/friends"
          onClick={onClickClose}
        >
          Refer a friend
        </AppLink>
        <AppLink
          className={cssSurvey}
          href={`${CLIENT_BASE_URL}${SITE_SURVEY_PATH}?${surveyParams.toString()}`}
          target="_blank"
          rel="noreferrer"
        >
          Retake skin quiz
          <ArrowTopRightOnSquareIcon
            className="ml-1x h-5 w-5 self-center"
            aria-hidden="true"
          />
        </AppLink>
        {created && renderedMyAccount}
        {!visitor && renderedLogout}
        {isVisitor && renderedLogin}
      </div>
    </div>
  );
};

import { AutocompleteQuerySuggestionsHit } from '@algolia/autocomplete-plugin-query-suggestions/dist/esm/types';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';

import { ALGOLIA_ID, ALGOLIA_KEY } from '@src/config/settings';
import { CollectionCategoryType } from '@src/generated/hooks';

export type ProductRecord = {
  brand: string;
  budget: string;
  category: string;
  category_name: string;
  full_name: string;
  haldi_status: string;
  index_name: string;
  name: string;
  objectID: string;
  rating: string;
  verified: string;
  visible: string;
};

export type ProductHit = AutocompleteQuerySuggestionsHit & ProductRecord;

/**
 * Returns the products search string, fully dependent on pregnancy status
 */
export const buildSearchFilterString = (isPregnancy: boolean) => {
  const defaultFilter = `(haldi_status:haldi_active OR haldi_status:haldi_no_recommend OR haldi_status:haldi_out_of_stock) AND visible:yes`;

  return isPregnancy
    ? `${defaultFilter} AND pregnancy_safe:yes`
    : defaultFilter;
};

/**
 * Returns a string used to filter products by category for the survey
 * @param categories List of categories to filter by. These are joined by an OR.
 * @returns String in the format required for Algolia to filter items
 */
export const buildCategoryFilterString = (
  categories: CollectionCategoryType[]
) => {
  /**
   * For the format on defining a filter on an attribute, use the filter string.
   * @external https://www.algolia.com/doc/api-reference/api-parameters/filters/
   */
  let searchString = '';
  if (categories.length) {
    searchString = categories
      .map((category) => `category:${category}`)
      .join(' OR ');
    searchString = `(${searchString}) AND `;
  }

  return `${searchString}visible:yes AND NOT brand:your`;
};

/**
 * @name getAlgoliaClient
 * @description Returns a search client that be used for our autocomplete
 * inputs.
 */
export const getAlgoliaClient = (): SearchClient => {
  if (!ALGOLIA_ID || !ALGOLIA_KEY) {
    throw new Error('Algolia credentials are required!');
  }

  const client = algoliasearch(ALGOLIA_ID, ALGOLIA_KEY);

  return client;
};

/**
 * Returns a boolean indicating if the query string is one of the hit items.
 * @param hits Hits returned from the Algolia search
 * @param query Query string used to do the search
 * @returns True if the query string is one of the items
 */
export const isQueryInResults = (
  hits: ProductHit[][],
  query: string
): boolean => {
  for (let i = 0; i < hits.length; i++) {
    const hitArray = hits[i];
    for (let j = 0; j < hitArray.length; j++) {
      const hit = hitArray[j];
      if (query.toLowerCase() === hit.full_name.toLowerCase()) {
        return true;
      }
    }
  }

  return false;
};

import { CartProduct } from '@src/generated/hooks';

/**
 * Simple function used to take an array of products and `[].reduce` to
 * get the total items (with quantity).
 */
export const getProductTotals = (
  total: number,
  currentValue: CartProduct
): number => {
  return total + currentValue.quantity;
};

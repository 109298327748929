import * as React from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useRecoilValue } from 'recoil';

import { CLIENT_KUSTOMER_CHAT, IS_BROWSER } from '@src/config/settings';
import { useRoutes } from '@src/hooks/useRoutes';
import { useUrlParams } from '@src/hooks/useUrlParams';
import { userAtom } from '@src/recoil/atoms/user';
import { checkKustomerChat, createJwtToken } from '@src/utils/kustomer';
import { logger } from '@src/utils/logger';

export interface AppKustomerChatProps {}

/**
 * @name AppKustomerChat
 * @external https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/overview-chat-ui-api-reference Web Chat SDK
 * @description An embeddable Kustomer chat widget
 */
export const AppKustomerChat = (_props: AppKustomerChatProps) => {
  // Hooks
  const { pathname } = useRouter();
  const { code } = useUrlParams();
  const { isChatAllowed } = useRoutes();
  const { email } = useRecoilValue(userAtom);

  // Setup
  const loginPayload = email ? { email } : { externalId: code };

  // Handlers
  const onOpenHandler = () => {
    window.Kustomer.isLoggedIn(loginPayload, async (res: boolean) => {
      if (!res) {
        logger.info(`🟡 Logging in...`);

        const jwtToken = await createJwtToken(loginPayload);
        window.Kustomer.login({ jwtToken }, (res: any, error: any) => {
          if (!error) {
            window.Kustomer.createConversation();

            logger.info(
              { email: res.email, externalId: res.externalId },
              `🟢 Logged in 🟢`
            );
          } else {
            logger.error({ error }, `🔴 Error logging in!`);
          }
        });
      } else {
        logger.info(`👨‍💻 Already logged in`);
      }
    });
  };

  const initializeChat = () => {
    try {
      const res = window.Kustomer.isChatAvailable();
      if (res?.availability) return; // disabled | offline | online | hidden | none
    } catch (e) {
      // Kustomer has not been initialized yet
    }

    logger.info(`🟡 Initializing chat...`);

    window.Kustomer.start(
      {
        brandId: '5e694c6b7bed1a001927df21',
        showBrowserNotifications: true,
        showTitleNotifications: true
      },
      () => {
        logger.info(`🟢 Chat initialized`);
        window.Kustomer.addListener('onOpen', onOpenHandler);
      }
    );
  };

  // Life Cycle
  React.useEffect(() => {
    (async () => {
      if (!IS_BROWSER || !isChatAllowed) return;

      const kustomer = await checkKustomerChat();
      if (kustomer) {
        initializeChat();
      }
    })();
  }, [pathname, IS_BROWSER]);

  // Optionally close the chat on unallowed routes
  // React.useEffect(() => {
  //   if (!isChatAllowed) kustomer?.stop?.();
  // }, [pathname]);

  return (
    <Script
      id="kustomer-chat"
      strategy="afterInteractive"
      data-kustomer-api-key={CLIENT_KUSTOMER_CHAT}
      src="https://cdn.kustomerapp.com/chat-web/widget.js"
    />
  );
};

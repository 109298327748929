import { atom } from 'recoil';

import { Authorization } from '@src/types';

export const authDefault: Authorization = {
  /**
   *  Have we checked at least once to see if the user is logged in.
   */
  checked: false
};

/**
 * @name authAtom
 * @description This atom stores any auth related info. This is different from
 * the user atom in that this stores things about the auth system rather than
 * who is logged in.
 */
export const authAtom = atom<Authorization>({
  default: authDefault,
  key: 'auth'
});

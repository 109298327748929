import * as React from 'react';
import { useCookies } from 'react-cookie';
import classnames from 'classnames';
import { useSetRecoilState } from 'recoil';

import { COOKIE_USER_ID } from '@src/config/constants';
import { appAtom } from '@src/recoil/atoms/app';
import { NavCart } from '@src/services/navigation/components/NavCart';
import { NavHamburger } from '@src/services/navigation/components/NavHamburger';
import {
  searchHaldiBrands,
  searchHaldiCategories,
  searchHaldiProducts
} from '@src/utils/algolia-search';
import { NavLogoMama } from '../NavLogoMama/NavLogoMama';
import { NavSearch } from '../NavSearch/NavSearch';

export interface NavShopMobileProps {
  className?: string;
}

/**
 * @name NavShopMobile
 * @description Very simple navigation bar we want across the application
 */
export const NavShopMobile = (props: NavShopMobileProps) => {
  const { className } = props;

  // Hooks
  const [cookies] = useCookies([COOKIE_USER_ID]);
  const uuid = cookies[COOKIE_USER_ID];
  const [isSearching, setIsSearching] = React.useState(false);
  const setBlur = useSetRecoilState(appAtom);

  // Handlers
  const handleSearchClick = () => {
    setIsSearching(!isSearching);
  };
  const handleSearchBlur = () => {
    setIsSearching(false);
    setBlur((state) => ({ ...state, typing: false }));
  };

  // Styles
  const cssComponent = classnames(`ui-container-lg`, className);
  const cssBtn = 'flex items-center justify-center h-full w-[60px]';

  // Markup
  const renderLogo = () => (
    <NavLogoMama className="mt-1xs max-w-[200px] p-2xs" />
  );
  return (
    <div className={cssComponent}>
      <div className="z-[100] flex h-full items-center align-middle">
        <NavHamburger className={cssBtn} />
        <img
          alt="Magnifying glass"
          className="w-4 hover:cursor-pointer"
          src="/icons/magnifier.svg"
          onClick={handleSearchClick}
        />
        <div className="flex-1" />
        {renderLogo()}
        <div className="flex-1" />
        <NavCart className={cssBtn} uuid={uuid} />
      </div>
      {isSearching && (
        <div>
          <div className="absolute top-[54px] left-0 w-full">
            <div
              className="flex h-14 items-center border-x-0 border-b-0 border-t-2 border-solid border-t-gray-1 bg-white"
              onBlur={handleSearchBlur}
            >
              <NavSearch
                suggestionPlugin={searchHaldiProducts(true)}
                brandPlugin={searchHaldiBrands(true)}
                categoryPlugin={searchHaldiCategories(true)}
              />
            </div>
          </div>
          <div
            className="absolute right-6 mt-5 hover:cursor-pointer"
            onClick={handleSearchClick}
          >
            <img
              alt="Close"
              width="18"
              height="18"
              src="/images/svg/close.svg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

/* eslint-disable prefer-destructuring */

/**
 * @note 👀 ⚠️ 👀  "prefer-destructuring" must remain disabled and unused 👀 ⚠️ 👀
 * @external https://vercel.com/docs/environment-variables
 * @external https://vercel.com/docs/environment-variables#system-environment-variables
 * @external https://vercel.com/docs/projects/environment-variables
 *
 * @description Survey Variables which we create specific `instances` of
 * based on the Vercel deployment type.
 */
export const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || 'staging';
export const VERCEL_REF = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;
export const VERCEL_SHA = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;
export const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL;
export const VERCEL_BRANCH = VERCEL_REF
  ? `https://client-git-${VERCEL_REF}-haldi.vercel.app`
  : `https://haldi.com`;

// Application settings
export const ALGOLIA_ID = process.env.ALGOLIA_ID;
export const ALGOLIA_KEY = process.env.ALGOLIA_KEY;
export const CLIENT_FIREBASE_API_KEY = process.env.CLIENT_FIREBASE_API_KEY;
export const CLIENT_FIREBASE_AUTH_DOMAIN = process.env.CLIENT_FIREBASE_AUTH_DOMAIN; // prettier-ignore
export const CLIENT_GOOGLE_ANALYTICS = process.env.CLIENT_GOOGLE_ANALYTICS || '__unavailable__'; // prettier-ignore
export const CLIENT_GOOGLE_TAG_MANAGER = process.env.CLIENT_GOOGLE_TAG_MANAGER || '__unavailable__'; // prettier-ignore
export const CLIENT_KUSTOMER_CHAT = process.env.CLIENT_KUSTOMER_CHAT || '__unavailable__'; // prettier-ignore
export const CLIENT_SHOPIFY_STORE = process.env.CLIENT_SHOPIFY_STORE || `__unavailable__`; // prettier-ignore
export const NODE_ENV = process.env.NODE_ENV || 'development';

export const FIREBASE_APP = process.env.FIREBASE_APP || 'haldi-dev';
export const FIREBASE_EMULATOR = process.env.FIREBASE_EMULATOR || 'false';
export const FIREBASE_STORAGE_BUCKET = process.env.FIREBASE_STORAGE_BUCKET || 'static.haldiskin.dev'; // prettier-ignore
export const GRAPHCMS_TOKEN = process.env.GRAPHCMS_TOKEN || '__unavailable__';
export const GRAPHCMS_URL = process.env.GRAPHCMS_URL || '__unavailable__';
export const GRAPHQL_URL = process.env.GRAPHQL_URL || '__unavailable__';
export const KUSTOMER_SECRET = process.env.KUSTOMER_SECRET || '__unavailable__'; // prettier-ignore
export const MIXPANEL_TOKEN = process.env.MIXPANEL_TOKEN || '__unavailable__';

// Basic checks...
export const IS_BROWSER = typeof window !== 'undefined';
export const IS_DEVELOPMENT = NODE_ENV === 'development';
export const IS_EMULATOR = FIREBASE_EMULATOR === 'true';
export const IS_PRODUCTION = NODE_ENV === 'production';
export const IS_PRODUCTION_ENV = VERCEL_ENV === 'production';
export const IS_TEST = NODE_ENV === 'test';

/**
 * ⚠️ Vercel does not account for domain names in NEXT_PUBLIC_VERCEL_URL ⚠️
 *
 * The environment variable NEXT_PUBLIC_VERCEL_URL does not account for domain
 * names and it will always be the Vercel hash url regardless of what domain
 * name is attached to it. Example: For "staging.haldiskin.dev",
 * NEXT_PUBLIC_VERCEL_URL is something like  "my-site-7q03y4pi5.vercel.app".
 *
 * This causes problems when we need to have the
 * actual url for things like redirect, <head> tags, and SEO. So if we have
 * an environment variable for what we know the domain should be, we'll use
 * that. All other deployments (like development branches), can use the
 * Vercel Url just fine.
 */
export const CLIENT_EXTERNAL_URL = process.env.CLIENT_EXTERNAL_URL
  ? process.env.CLIENT_EXTERNAL_URL
  : VERCEL_BRANCH;

export const CLIENT_INTERNAL_URL = process.env.CLIENT_INTERNAL_URL
  ? process.env.CLIENT_INTERNAL_URL
  : VERCEL_BRANCH;

export const CLIENT_BASE_URL = IS_BROWSER
  ? CLIENT_EXTERNAL_URL
  : CLIENT_INTERNAL_URL;

import { SignJWT } from 'jose';

import { IS_BROWSER, KUSTOMER_SECRET } from '@src/config/settings';
import { sleep } from './time';

export async function createJwtToken(payload: Record<string, any>) {
  const secret = new TextEncoder().encode(KUSTOMER_SECRET);

  return await new SignJWT(payload)
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setExpirationTime('1h')
    .sign(secret);
}

/**
 * @name checkKustomerChat
 * @description Recurvisely check if Kustomer is loaded yet, return the function if it has
 */
export async function checkKustomerChat(retries = 40) {
  const kustomer = IS_BROWSER ? window.Kustomer : undefined;

  if (kustomer) {
    return true;
  } else if (retries > 0) {
    await sleep(250);
    return await checkKustomerChat(retries - 1);
  }

  return false;
}

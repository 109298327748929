import * as React from 'react';

import { useRoutes } from '@src/hooks/useRoutes';
import { NavMobileAcquisition } from './NavMobileAcquisition';
import { NavMobileShop } from './NavMobileShop';
import { NavMobileStore } from './NavMobileStore';

export interface NavMobileProps {}

/**
 * @name NavMobile
 * @description Our mobile navigation is an overlay type of menu. Depending on
 * the "route" we show 1 of 2 sets of links so we manage that logic here.
 */
export const NavMobile = (_props: NavMobileProps) => {
  // Hooks
  const { isAcquisitionRoute, isAnonymousRoute } = useRoutes();

  // Special navbar for shop
  if (isAnonymousRoute) {
    return <NavMobileShop />;
  }

  return isAcquisitionRoute ? <NavMobileAcquisition /> : <NavMobileStore />;
};

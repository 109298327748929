import { ApolloLink, createHttpLink, fromPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import {
  COOKIE_AUTH_REFRESH,
  COOKIE_AUTH_TOKEN,
  HEADER_AUTH_KEY,
  HEADER_AUTH_REFRESH,
  HEADER_USER_ID
} from '@src/config/constants';
import {
  GRAPHQL_URL,
  IS_BROWSER,
  IS_PRODUCTION_ENV
} from '@src/config/settings';
import { refreshAuthToken, setTempCookie } from '@src/utils/authentication';
import { logger } from '@src/utils/logger';

/**
 * @name linkAuth
 * @description Sets the authentication header(s) for every request with
 * the "id_token" and "refresh_token" when they are available.
 */
const linkAuth = (token?: string, refresh?: string): ApolloLink => {
  return setContext(async (_, setter) => {
    const { headers = {} } = setter;

    if (token) headers[HEADER_AUTH_KEY] = token;
    if (refresh) headers[HEADER_AUTH_REFRESH] = refresh;

    return { headers };
  });
};

/**
 * @name linkCode
 * @description Pass the users ID (code) along for the ride if it's
 * available, allowing us to preview our users and share publicly
 */
const linkCode = (code?: string): ApolloLink => {
  return setContext(async (_, setter) => {
    const { headers = {} } = setter;

    if (code) headers[HEADER_USER_ID] = code;

    return { headers };
  });
};

/**
 * @name linkError
 * @description Log any GraphQL errors or network error that occurred
 */
const linkError = onError((errorHandler) => {
  const { forward, graphQLErrors, networkError, operation } = errorHandler;

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const { message, locations, path, stack, extensions } = err;

      const headers = operation.getContext().headers;
      const tokenCurrent = headers[HEADER_AUTH_KEY];
      const isAuthError = (extensions?.exception as any)?.code ?? false;
      const refreshToken = headers[HEADER_AUTH_REFRESH];

      if (isAuthError && refreshToken) {
        return fromPromise(
          refreshAuthToken(refreshToken).then((response) => {
            const { data } = response;
            const tokenNew = data?.authentication.idToken;
            const refreshNew = data?.authentication.refreshToken;
            const isTokenNew = tokenCurrent !== tokenNew;

            if (refreshNew) headers[HEADER_AUTH_REFRESH] = refreshNew;
            if (tokenNew) headers[HEADER_AUTH_KEY] = tokenNew;

            if (IS_BROWSER && isTokenNew) {
              setTempCookie(COOKIE_AUTH_REFRESH, refreshNew);
              setTempCookie(COOKIE_AUTH_TOKEN, tokenNew);
            }

            operation.setContext({ headers });
          })
        ).flatMap(() => {
          const op = operation.operationName;
          const msg = `⚠️ Retrying operation ⚠️ "${op}"`;

          if (!IS_PRODUCTION_ENV) logger.warn(msg);

          // Retry the request, returning the new observable
          return forward(operation);
        });
      }

      if (!IS_PRODUCTION_ENV) {
        const ignoreErrors = ['Customer id or email must be provided'];
        if (ignoreErrors.includes(message)) return;

        logger.error({ locations, path, stack }, `GraphQL error: ${message}`);
      }
    }
  }

  if (networkError) console.log(`Network error: ${networkError}`);

  return;
});

/**
 * @Name linkHTTP
 * @description This needs to be created here and not in the global
 * context otherwise when running the tests, there is some global fetch
 * dependency.
 */
const linkHTTP = (): ApolloLink => {
  return createHttpLink({
    credentials: 'same-origin',
    uri: GRAPHQL_URL
  });
};

/**
 * @name linkLogger
 * @description Custom "link" used to capture some logs for debugging
 */
const linkLogger = new ApolloLink((operation, forward) => {
  const data = { name: operation.operationName };
  operation.setContext({ start: new Date() });

  // const useLogger = !IS_PRODUCTION_ENV;
  const useLogger = false;

  if (useLogger) logger.info(data, 'Apollo link operation');

  return forward(operation);
});

export { linkAuth, linkCode, linkLogger, linkError, linkHTTP };

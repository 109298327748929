import * as React from 'react';
import classnames from 'classnames';

import { CartProduct, PersonaStoreProduct } from '@src/generated/hooks';
import { useRoutes } from '@src/hooks/useRoutes';
import { AppLink } from '@src/services/app/components/AppLink/AppLink';
import { AppPicture } from '@src/services/app/components/AppPicture/AppPicture';
import { getProductImage } from '@src/utils/images';

import styles from './styles.module.css';

export interface DropdownCartItemProps {
  code: string;
  data: CartProduct;
  productData?: PersonaStoreProduct;
}

/**
 * @name DropdownCartItem
 * @description Simple component preview of a Product in our users cart
 */
export const DropdownCartItem = (props: DropdownCartItemProps) => {
  const { code, data, productData } = props;

  const { isAnonymousRoute } = useRoutes();
  const pathname = isAnonymousRoute ? '/shop-mama' : '/store';

  // Setup
  const label = productData?.recommended.id;
  const isTopPick = label === 'top';
  const isPurchased = !!productData?.purchased;
  const section = isPurchased ? 'purchased' : isTopPick ? 'top' : 'discovery';

  const { category, image, name, price, productId, quantity, variant } = data;
  const amount = price.toFixed(2);
  const { jpg, webp } = getProductImage(image, 400);
  const params = new URLSearchParams({
    brand: 'all',
    category: section === 'discovery' ? category : 'all',
    code,
    product: productId
  });
  if (!isAnonymousRoute) params.append('section', section);
  const url = `${pathname}?${params.toString()}`;

  // Styles
  const cssComponent = classnames('flex', styles.component);

  // Markup
  const renderImage = () => {
    if (!image) return null;

    return (
      <AppLink className={styles.image} href={url}>
        <AppPicture className={styles.image} alt={name} jpg={jpg} webp={webp} />
      </AppLink>
    );
  };

  return (
    <div className={cssComponent}>
      {renderImage()}
      <AppLink className="no-underline" href={url}>
        <div>
          <p>
            {name} - {variant}
          </p>
          <p className="font-semibold">
            ${amount} x {quantity}
          </p>
        </div>
      </AppLink>
    </div>
  );
};

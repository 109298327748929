import * as React from 'react';
import classnames from 'classnames';

import { useRoutes } from '@src/hooks/useRoutes';
import { NavHamburger } from '@src/services/navigation/components/NavHamburger';
import { NavLogo } from '@src/services/navigation/components/NavLogo';

import styles from './navAcquisitionMobile.module.css';

export interface NavAcquisitionMobileProps {
  className?: string;
}

/**
 * @name NavAcquisitionMobile
 * @description Very simple navigation bar we want across the application
 */
export const NavAcquisitionMobile = (props: NavAcquisitionMobileProps) => {
  const { className } = props;

  // Hooks
  const { isActive, isNavForest } = useRoutes();

  // Setup
  const background = isNavForest ? 'forest' : 'white';

  // Styles
  const tailwind = `ui-container-lg`;
  const cssBtn = 'flex items-center justify-center h-full w-[60px]';
  const cssLogo = classnames('p-2xs', styles.logo);
  const cssComponent = classnames(tailwind, styles.component, className, {
    'bg-forest': isNavForest
  });

  // Markup
  const renderLogo = () => (
    <NavLogo background={background} className={cssLogo} />
  );

  return (
    <div className={cssComponent}>
      <div className="flex h-full items-center align-middle">
        <NavHamburger className={cssBtn} />
        <div className="flex-1" />
        {!isActive.homepage && renderLogo()}
        <div className="flex-1" />
        <div className={cssBtn} />
      </div>
    </div>
  );
};

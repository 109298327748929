import * as React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useRecoilState } from 'recoil';

import { modalAtom } from '@src/recoil/atoms/modal';

export interface AppModalProps {}

/**
 * @name AppModal
 * @description We interact with a singleton for our Modals which allows
 * us to keep the UI consistent and prevents us from having to deal with
 * potentially stacking issues.
 * @external https://tailwindui.com/components/application-ui/overlays/modals
 */
export const AppModal = (_props: AppModalProps) => {
  // Hooks
  const [state, setState] = useRecoilState(modalAtom);

  // Setup
  const { content, open, title } = state;

  // Handlers
  const closeModal = () => {
    setState({
      ...state,
      open: false
    });
  };

  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-5 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-7 pt-9 pb-14 text-left shadow-xl transition-all sm:w-full sm:max-w-xl md:max-w-2xl md:px-5x">
                <div className="absolute top-0 right-0 block pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-5 hover:text-gray-7 focus:outline-none "
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-start">
                  <div className="mt-0 min-h-0 w-full min-w-0 text-left">
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className="font-beatrice-regular text-2xl leading-6"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    <div>{content}</div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

import * as React from 'react';
import classnames from 'classnames';
import { useRecoilValue } from 'recoil';

import {
  SITE_HALDI_MAMA,
  SITE_SURVEY_CTA,
  SITE_SURVEY_PATH
} from '@src/config/constants';
import { useRoutes } from '@src/hooks/useRoutes';
import { userAtom } from '@src/recoil/atoms/user';
import { AppLink } from '@src/services/app/components/AppLink';
import { NavLogo } from '@src/services/navigation/components/NavLogo';
import { NavProfile } from '@src/services/navigation/components/NavProfile';

import styles from './navAcquisitionDesktop.module.css';

export interface NavAcquisitionDesktopProps {
  className?: string;
}

/**
 * @name NavAcquisitionDesktop
 * @description tbd...
 */
export const NavAcquisitionDesktop = (props: NavAcquisitionDesktopProps) => {
  const { className } = props;

  // Hooks
  const { isActive, isNavForest, isNavPine, isSustainability, isLoginLogout } =
    useRoutes();
  const { visitor } = useRecoilValue(userAtom);

  // Styles
  const cssBackground = classnames({
    'bg-forest': isNavForest,
    'bg-pine': isNavPine,
    'bg-seaSaltLight': isSustainability,
    'bg-white': !isNavForest && !isNavPine && !isSustainability
  });

  function getBGColor() {
    if (cssBackground === 'bg-forest') {
      return 'forest';
    } else if (cssBackground === 'bg-pine') {
      return 'pine';
    } else if (cssBackground === 'bg-seaSaltLight') {
      return 'seaSaltLight';
    } else {
      return 'white';
    }
  }

  const background = getBGColor();
  const cssComponent = classnames(cssBackground, styles.component, className);
  const cssLinkSurvey = classnames(styles.survey);

  const active = styles.active;
  const cssAbout = classnames(styles.link, { [active]: isActive['about-us'] });
  const cssLogin = classnames(styles.link, { [active]: isLoginLogout });
  const cssHaldiMama = classnames(styles.link);

  const renderLoginLink = () => (
    <AppLink className={cssLogin} href="/login">
      Login
    </AppLink>
  );

  const renderLogo = () => (
    <NavLogo background={background} className={styles.logo} />
  );

  const renderSurveyLink = () => (
    <AppLink className={cssLinkSurvey} href={SITE_SURVEY_PATH}>
      {SITE_SURVEY_CTA}
    </AppLink>
  );

  return (
    <div className={cssComponent}>
      <div className="ui-container-xl flex items-center">
        {!isActive.homepage && renderLogo()}
        <span className="flex-grow" />
        <AppLink className={cssAbout} href="/about-us">
          Our story
        </AppLink>
        <AppLink className={cssHaldiMama} href={SITE_HALDI_MAMA}>
          Haldi Mama
        </AppLink>
        {visitor && renderLoginLink()}
        {visitor && renderSurveyLink()}
        {!visitor && <NavProfile className="mx-2x" />}
      </div>
    </div>
  );
};

import * as React from 'react';
import classnames from 'classnames';

import { DropdownCart } from '@src/components/DropdownCart';
import { useGetCustomerCartQuery } from '@src/generated/hooks';
import { useRoutes } from '@src/hooks/useRoutes';
import { AppDropdown } from '@src/services/app/components/AppDropdown';
import { getProductTotals } from '@src/services/navigation/utils';

import styles from './styles.module.css';

export interface NavCartProps {
  className?: string;
  uuid?: string;
}

/**
 * @name NavCart
 * @description Shows the users current cart count and reveals the truncated
 * list of their current cart on hover
 */
export const NavCart = (props: NavCartProps) => {
  const { className, uuid } = props;

  // Hooks
  const { data } = useGetCustomerCartQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { input: { uuid } }
  });
  const { isNavForest } = useRoutes();

  // Setup
  const products = data?.customerCart.products ?? [];
  const quantity = products.reduce(getProductTotals, 0);

  // Setup
  const img = isNavForest ? 'bag-turmeric' : 'bag';
  const src = `/images/svg/${img}.svg`;

  // Styles
  const cssComponent = classnames(styles.component, className);
  const cssCounter = classnames('ml-1xs text-16', styles.counter, {
    'text-turmeric': isNavForest
  });

  // Markup
  const Content = (props: any) => {
    const classes = classnames(props.className, cssComponent);

    return (
      <div {...props} className={classes}>
        <img alt="Your Bag" className={styles.bag} src={src} />
        <div className={cssCounter}>
          (<span>{quantity}</span>)
        </div>
      </div>
    );
  };

  return (
    <AppDropdown content={Content} dropdown={DropdownCart} {...{ uuid }} />
  );
};

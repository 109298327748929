import * as React from 'react';
import { useCookies } from 'react-cookie';
import classnames from 'classnames';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  COOKIE_USER_ID,
  SITE_SURVEY_CTA,
  SITE_SURVEY_PREGNANCY_PATH
} from '@src/config/constants';
import { useRoutes } from '@src/hooks/useRoutes';
import { appAtom } from '@src/recoil/atoms/app';
import { userAtom } from '@src/recoil/atoms/user';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';
import { NavCart } from '@src/services/navigation/components/NavCart';
import {
  searchHaldiBrands,
  searchHaldiCategories,
  searchHaldiProducts
} from '@src/utils/algolia-search';
import { NavLogoMama } from '../NavLogoMama';
import { NavProfile } from '../NavProfile';
import { NavSearch } from '../NavSearch';

import styles from './navShopDesktop.module.css';

export interface NavShopDesktopProps {
  className?: string;
}

/**
 * @name NavShopDesktop
 * @description Very simple navigation bar we want across the application
 */
export const NavShopDesktop = (props: NavShopDesktopProps) => {
  const { className } = props;

  // Hooks
  const [cookies] = useCookies([COOKIE_USER_ID]);
  const uuid = cookies[COOKIE_USER_ID];
  const navRef = React.useRef<HTMLElement>(null);
  const { isLoginLogout } = useRoutes();
  const { email } = useRecoilValue(userAtom);
  const [isSearching, setIsSearching] = React.useState(false);
  const setBlur = useSetRecoilState(appAtom);

  // Setup
  // In anonymouse routes, visitors are false by default but email will be empty
  const isVisitor = !email;

  // Handlers
  const handleSearchClick = () => {
    setIsSearching(!isSearching);
  };
  const handleSearchBlur = () => {
    setIsSearching(false);
    setBlur((state) => ({ ...state, typing: false }));
  };

  // Styles
  // const cssLogin = classnames('flex items-center', styles.link);
  const cssComponent = classnames(styles.component, className);
  const cssGetRoutine = classnames(
    'self-center flex ml-2x px-6x h-[50px]',
    styles.routine
  );

  // Markup
  const renderLogin = () => {
    if (!isVisitor || isLoginLogout) return null;
    // Disabled for now
    return null;
    // return (
    //   <AppLink className={cssLogin} href="/login">
    //     Login
    //   </AppLink>
    // );
  };
  const renderGetRoutineLink = () => {
    if (!isVisitor || isLoginLogout) return null;

    return (
      <HaldiButton className={cssGetRoutine} href={SITE_SURVEY_PREGNANCY_PATH}>
        {SITE_SURVEY_CTA}
      </HaldiButton>
    );
  };
  const renderLogo = () => (
    <NavLogoMama className="mx-1x my-2xs max-w-[200px] p-2xs" />
  );
  return (
    <nav className={cssComponent} ref={navRef}>
      <div className="ui-container-xl align-center h-full w-full items-center">
        <div className="absolute top-0 left-0 w-full">
          <div className="flex h-[70px] w-full">
            {renderLogo()}
            {isSearching && (
              <div
                className="relative flex h-[70px] w-full items-center"
                onBlur={handleSearchBlur}
              >
                <img
                  alt="Magnifying glass"
                  className="w-4"
                  src="/icons/magnifier.svg"
                />
                <NavSearch
                  className="absolute left-0"
                  suggestionPlugin={searchHaldiProducts(true)}
                  brandPlugin={searchHaldiBrands(true)}
                  categoryPlugin={searchHaldiCategories(true)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="absolute right-0 top-0">
          <div className="flex h-[70px] bg-white">
            {renderLogin()}

            <span
              className={`mr-1xs flex h-full items-center font-beatrice-regular text-14 hover:cursor-pointer`}
              onClick={handleSearchClick}
            >
              {isSearching ? (
                <img
                  alt="Close"
                  width="20"
                  height="20"
                  src="/images/svg/close.svg"
                />
              ) : (
                <span>
                  <img
                    alt="Magnifying glass"
                    className="mr-1xs w-4 align-middle"
                    src="/icons/magnifier.svg"
                  />
                  Search
                </span>
              )}
            </span>

            {!isVisitor && <NavProfile className="pl-2x mr-0" />}
            {<NavCart className="mr-2x ml-3x" uuid={uuid} />}
            {renderGetRoutineLink()}
          </div>
        </div>
      </div>
    </nav>
  );
};

import * as React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  SITE_HALDI_MAMA,
  SITE_SURVEY_CTA,
  SITE_SURVEY_PREGNANCY_PATH
} from '@src/config/constants';
import { mobileAtom } from '@src/recoil/atoms/mobile';
import { userAtom } from '@src/recoil/atoms/user';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';
import { AppLink } from '@src/services/app/components/AppLink';

import styles from './styles.module.css';

export interface NavMobileShopProps {}

/**
 * @name NavMobileShop
 * @description This component is similar to our modal, it utilizes fixed
 * positioning and visibility is controlled by a hamburger button. This
 * route is shown on shop pages
 */
export const NavMobileShop = (_props: NavMobileShopProps) => {
  // Hooks
  const [mobile, setMobile] = useRecoilState(mobileAtom);
  const { pathname } = useRouter();
  const { email } = useRecoilValue(userAtom);

  // Setup
  const { open } = mobile;
  const isVisitor = !email;

  // Styles
  const tailwind = `flex flex-col ui-mobile gap-3xs mt-10x`;
  const cssContent = classnames(tailwind, styles.content);
  const cssComponent = classnames('px-3xs py-5x', styles.component, {
    [styles.open]: open
  });

  // Handlers
  const onClickClose = () => {
    setMobile((state) => ({ ...state, open: false }));
  };

  const getClasses = (path: string, uppercase = false) => {
    return classnames(styles.link, {
      [styles.active]: pathname === path,
      [styles.uppercase]: uppercase
    });
  };

  // Markup
  const renderTakeSurvey = () => {
    if (!isVisitor) return null;

    return (
      <HaldiButton
        bordersX="forest"
        bordersY="forest"
        className="mt-2x bg-white"
        href={SITE_SURVEY_PREGNANCY_PATH}
        onClick={onClickClose}
        thick={true}
      >
        {SITE_SURVEY_CTA}
      </HaldiButton>
    );
  };

  const renderUserProfile = () => {
    if (isVisitor) return null;

    return (
      <HaldiButton
        bordersX="forest"
        bordersY="forest"
        className="mt-2x bg-white"
        href="/profile/summary"
        onClick={onClickClose}
        thick={true}
      >
        My Profile
      </HaldiButton>
    );
  };

  return (
    <div className={cssComponent}>
      <button className={styles.close} onClick={onClickClose}>
        <img alt="Close navigation" src="/images/svg/close.svg" />
      </button>
      <div className={cssContent}>
        <AppLink
          className={getClasses('/about-us', true)}
          href="/about-us"
          onClick={onClickClose}
        >
          Our story
        </AppLink>
        <AppLink
          className={getClasses('/refer-a-friend', true)}
          href={SITE_HALDI_MAMA}
          onClick={onClickClose}
        >
          Haldi Mama
        </AppLink>
        {renderUserProfile()}
        {renderTakeSurvey()}
      </div>
    </div>
  );
};

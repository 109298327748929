import { atom } from 'recoil';
import parser from 'ua-parser-js';

export const userAgentDefault = parser('');

/**
 * @name userAgentAtom
 * @external https://github.com/faisalman/ua-parser-js
 * @description We use the package above to parse the browsers "user-agent"
 * header and return some device information / context. This can be used to
 * distinguish between mobile and desktop browsers for example.
 */
export const userAgentAtom = atom<parser.IResult>({
  default: userAgentDefault,
  key: 'userAgent'
});

import * as React from 'react';
import classnames from 'classnames';

import { DropdownProfile } from '@src/components/DropdownProfile';
import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { useRoutes } from '@src/hooks/useRoutes';
import { AppDropdown } from '@src/services/app/components/AppDropdown';

import styles from './styles.module.css';

export interface NavProfileProps {
  className?: string;
}

/**
 * @name NavProfile
 * @description Shows the initials and first name of the current user.
 * Upon hover we show a list of commonly accessed links.
 */
export const NavProfile = (props: NavProfileProps) => {
  const { className } = props;

  // Hooks
  const { data } = useGetCustomerInfoQuery();
  const { isNavForest, isAcquisitionRoute } = useRoutes();

  // Setup
  const first = data?.customerInfo?.firstName.charAt(0);
  const firstName = data?.customerInfo?.firstName ?? '';
  const last = data?.customerInfo?.lastName.charAt(0);
  const initials = first && last ? first + last : false;

  // Styles
  const tailwind = 'flex mr-1x items-center';
  const cssComponent = classnames(tailwind, styles.component, className, {
    [styles.store]: !isAcquisitionRoute
  });

  const cssName = classnames('ml-1x', { 'text-turmeric': isNavForest });

  // Markup
  const renderContent = (_props: unknown) => (
    <div className={cssComponent}>
      <div className={styles.circle}>{initials || renderedImage}</div>
      <div className={cssName}>{firstName}</div>
      <img alt="" className="ml-1x" src="/images/rebrand/arrow.svg" />
    </div>
  );

  const renderedImage = (
    <img alt="User" className={styles.image} src="/images/svg/user.svg" />
  );

  return <AppDropdown content={renderContent} dropdown={DropdownProfile} />;
};

import { MetadataConfig } from '@src/services/seo/config/defaults';

const getGoogleMeta = (
  metadata: MetadataConfig
): React.MetaHTMLAttributes<HTMLMetaElement>[] => [
  {
    content: metadata.description,
    itemProp: 'description'
  },
  {
    content: metadata.image,
    itemProp: 'image'
  },
  {
    content: metadata.title,
    itemProp: 'name'
  }
];

export { getGoogleMeta };

import * as React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';

import { CollectionSectionType, sectionToName } from '@src/config/maps';

export interface NavStoreTitleProps {
  className?: string;
}

/**
 * @name NavStoreTitle
 * @description Renders the title for the navbar if we are on a store page. This
 * will only render on mobile and if we have scrolled past a certain point.
 */
export const NavStoreTitle = (props: NavStoreTitleProps) => {
  const { className } = props;

  // Hooks
  const { query } = useRouter();

  // Setup
  const section = (query.section ?? 'top') as CollectionSectionType;
  const isTop = section === 'top';
  const isPurchased = section === 'purchased';
  const isDiscovery = section === 'discovery';
  const isHoliday = section === 'holiday';

  return (
    <div
      className={classnames(
        'pt-1 font-beatrice-light text-18 tracking-[0.0025em] text-forest',
        className
      )}
    >
      {isTop && sectionToName('top')}
      {isPurchased && sectionToName('purchased')}
      {isDiscovery && sectionToName('discovery')}
      {isHoliday && sectionToName('holiday')}
    </div>
  );
};

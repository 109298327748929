import * as React from 'react';
import classnames from 'classnames';

import { COOKIE_BANNER } from '@src/config/constants';
import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { useRoutes } from '@src/hooks/useRoutes';
import { useSessionStorage } from '@src/hooks/useSessionStorage';
import { shouldShowGiftWithPurchase } from '@src/routes/store/utils';
import { AppBannerType } from '@src/types';
import { isHolidayPeriod } from '@src/utils/time';

import styles from './styles.module.css';

export interface AppBannerProps {
  className?: string;
}

/**
 * @name AppBanner
 * @description A promotional banner that sits at the top of the application.
 * We can quickly update copy or the markup as needed and control tweak it
 * however we need to, as we need to.
 */
export const AppBanner = (props: AppBannerProps) => {
  const { className } = props;

  // Hooks
  const [isClosed, setIsClosed] = useSessionStorage(COOKIE_BANNER, false);
  const { isChatAllowed } = useRoutes();
  const { data, loading } = useGetCustomerInfoQuery();

  // Setup
  const giftTitle = data?.productsGift?.title;
  const lifecycle = data?.customerInfo?.lifecycle ?? [];
  const isCustomer = lifecycle.includes('customer');
  const isPregnancy = data?.customerInfo?.pregnancy ?? true;
  const isPregnancySafe = data?.productsGift?.pregnancySafe !== 'no';

  const isHoliday = isHolidayPeriod();
  const showGiftWithPurchase = shouldShowGiftWithPurchase({
    giftWithPurchaseStatus: data?.productsGift?.giftWithPurchaseStatus,
    isCustomer,
    isPregnancy,
    isPregnancySafe
  });

  // Determine if we need to show the banner and which type to show.
  // Since we can only have 1 banner at a time, order matters
  let banner: AppBannerType | null = null;
  if (!isCustomer) banner = 'welcome';
  if (showGiftWithPurchase) banner = 'gift_with_purchase';
  if (isHoliday) banner = 'holiday';
  // banner = 'alert';

  const isBannerPage = isChatAllowed || banner === 'holiday'; // Only holiday sales are site-wide

  // Styles
  const cssComponent = classnames(styles.component, styles.light, className);

  // Handlers
  const onClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsClosed(true);
  };

  // Markup
  const renderWelcome = () => {
    return (
      <span>
        Welcome to Haldi 💛 Use <b>WELCOME20</b>{' '}
        <span className="block sm:inline">
          for <b>20% off</b> your 1st purchase
        </span>
      </span>
    );
  };
  const renderGiftWithPurchase = () => {
    return (
      <span>
        Your fave {giftTitle}{' '}
        <span className="block sm:inline">
          <b>with any $75+ purchase</b> - on us
        </span>
      </span>
    );
  };
  const renderHoliday = () => {
    return (
      <span>
        <span className="block sm:inline">Our 1st Spring Sale is here! 🌟</span>
        <b>20% off everything.</b> No code needed.{' '}
      </span>
    );
  };
  // const renderAlert = () => {
  //   return (
  //     <span>
  //       We're sorry for the inconvenience, but we're temporarily out of stock.
  //       The good news is we'll be restocking soon and can't wait to help with
  //       your summer skincare! 🌞
  //     </span>
  //   );
  // };

  // 🔌 Short Circuit
  if (loading || isClosed || !isBannerPage || !banner) return null;

  return (
    <div className={cssComponent}>
      <div className="ui-container-xl relative font-beatrice-light">
        {banner === 'welcome' && renderWelcome()}
        {banner === 'gift_with_purchase' && renderGiftWithPurchase()}
        {banner === 'holiday' && renderHoliday()}
        {/* {banner === 'alert' && renderAlert()} */}
      </div>
      <button className={styles.button} onClick={onClickClose} type="button">
        &times;
      </button>
    </div>
  );
};

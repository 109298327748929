import * as React from 'react';

import { HaldiButton } from '@src/routes/common/components/HaldiButton';

export interface NoReferralsProps {}

/**
 * @name NoReferrals
 * @description This component is used to display a message when a user
 * has no referrals in hope of them spreading the word about Haldi.
 */
export const NoReferrals = (_props: NoReferralsProps) => {
  return (
    <div>
      <img alt="" className="my-2x" src="/images/refer-a-friend/products.jpg" />
      <p>
        Share Haldi with your friends. As a thank you, we'd love to give you
        free skincare.
      </p>
      <HaldiButton className="mt-2x w-full" href="/profile/friends">
        Learn how
      </HaldiButton>
    </div>
  );
};

import * as React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { SITE_TITLE } from '@src/config/constants';
import { CLIENT_BASE_URL } from '@src/config/settings';
import { getMetadata } from '@src/services/seo/metadata';

export interface AppHeadProps {}

/**
 * @name AppHead
 * @description A simple component that we use to set the "base" information
 * used in our Document <head>. This can be further "extended" by any
 * sub-routes, this is simply a strong base
 */
export const AppHead = (_props: AppHeadProps) => {
  // Hooks
  const { pathname } = useRouter();

  // Setup
  const metadata = getMetadata(pathname);

  // Markup
  const renderMetaTag = (
    props: React.MetaHTMLAttributes<HTMLMetaElement>,
    index: number
  ) => <meta {...props} key={index} />;

  return (
    <Head>
      <link href={`${CLIENT_BASE_URL}${pathname}`} rel="canonical" />
      <link href="/favicon.ico" rel="icon" />
      <link href="/images/icon.png" rel="apple-touch-icon" sizes="48x48" />
      <link href="/manifest.json" rel="manifest" />
      <link rel="preconnect" href="https://www.googletagmanager.com" />
      <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
      {metadata.map(renderMetaTag)}
      <title>{SITE_TITLE}</title>
    </Head>
  );
};

import * as React from 'react';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { IS_BROWSER } from '@src/config/settings';
import { useFirebaseAuth } from '@src/hooks/useFirebaseAuth';
import { useUrlParams } from '@src/hooks/useUrlParams';
import { userAtom } from '@src/recoil/atoms/user';
import {
  checkAnalytics,
  trackingInitialize,
  trackingUserData
} from '@src/utils/tracking';

export interface AppLifeCycleProps {}

/**
 * @name AppLifeCycle
 * @description We use this component to mount any Application specific
 * logic that we need to initialize on every route change, while still having access to
 * both Apollo and Recoil state.
 *
 * We're using it for mount and un-mount
 */
export const AppLifeCycle = (_props: AppLifeCycleProps) => {
  // Hooks
  useFirebaseAuth();
  const { pathname } = useRouter();
  const { code } = useUrlParams();
  const user = useRecoilValue(userAtom);

  // Life Cycle
  React.useEffect(() => {
    (async () => {
      if (!IS_BROWSER) return;

      const isLoaded = await checkAnalytics();
      if (isLoaded) {
        trackingInitialize();
        trackingUserData([], user, code);
      }
    })();
  }, [pathname, IS_BROWSER]);

  return null;
};
